import React from 'react';
import useBodyScrollLock from './useBodyScrollLock';



const WelcomePopup = ({ onClose, displayName }) => {
  const [, setBodyScrollLocked] = useBodyScrollLock(true);  // Start with scroll locked

  const handleClose = () => {
    setBodyScrollLocked(false);  // Unlock scroll when closing
    onClose();
  };
  
  return (
    <div className="welcome-popup">
      <div className="welcome-popup__content no-scroll">
      <div className="welcome-popup__content-wrapper no-scroll">
        <h2 className="welcome-popup__title">Hey {displayName}! Tio here 👋</h2>
        <p className="welcome-popup__message ">
         We're so excited to ahve you as part of the Tio community! We're just getting started, and you being here means the world to us.
        </p>
        <div className="welcome-popup__info">

        <div className="welcome-services__pic">
      <img src={`/images/Tios/Growing-Tio.webp`} alt="TIO illustration" className="tio-image-welcome" />
      </div>

          <p>We're a growing platform, which means you might not find all the experts you're looking for just yet. But don't worry, we're expanding every day!</p>
        </div>
        <p className="welcome-popup__title">While we grow, here's how you can be awesome:</p>
        <ul className="welcome-popup__list align-left-on-mobile">
          <li className="welcome-popup__list-item">

            <div className="welcome-popup__list-icon"  />
            <svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path 
    d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
    fill="none"
    stroke="url(#gradient)"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>



<span className='ps-3'>
            Explore the platform and chat with me anytime
            </span>
          </li>
          <li className="welcome-popup__list-item">
          <div className="welcome-popup__list-icon"  />
          <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 24 24" fill="none">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" strokeWidth={1.5} stroke="url(#gradient)" />
</svg>

<span className='ps-3'>
            Consider becoming an expert yourself
            </span>
          </li>
          <li className="welcome-popup__list-item">
              <div className="welcome-popup__list-icon"  />

              <svg xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 24 24" fill="none">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" strokeWidth={1.5} stroke="url(#gradient)" />
</svg>




<span className='ps-3'>
            Share your thoughts and help shape our community
            </span>
          </li>
        </ul>
        <p className="welcome-popup__cta ">
          Your support in these early days is invaluable. If you enjoy your experience, a review or social media share would mean the world to us!
        </p>
        <button className="btn btn-primary cartoon-shadow fw-bold " onClick={onClose}>Let's explore together!</button>
      </div>
    </div>
    </div>
  );
};

export default WelcomePopup;