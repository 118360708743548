import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import SearchResultCard from './SearchResultCard';
import Pagination from './Pagination';
import Fuse from 'fuse.js';
import { FaThumbsUp, FaTrophy, FaRocket } from 'react-icons/fa';

const SearchResults = ({ results, isOpen, noResultsMessage, onClose, otherUserUIDs, isFromSearchBar }) => {
  const resultsRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('relevance');
  const [selectedCommunicationMethod, setSelectedCommunicationMethod] = useState('all');
  const [filteredResults, setFilteredResults] = useState([]);
  const [jobSuccess, setJobSuccess] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [skillsQuery, setSkillsQuery] = useState('');
  const [otherUserUID, setOtherUserUID] = useState([]);
  const [hasFilter, setHasFilter] = useState(false);
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.body.classList.remove('modal-open');
    };
  }, [isOpen, onClose]);

  const handleSearch = async () => {
    console.log('Starting search...'); // Log start of search
    const expertsCollection = collection(firestore, 'expertApplications');

    try {
      console.log('Fetching search results...'); // Log before fetching data

      const querySnapshot = await getDocs(expertsCollection);
      const searchData = [];
      const userUIDs = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const uid = doc.id;
        searchData.push({ ...data, otherUserUID: uid });
        userUIDs.push(uid);
      });

      console.log('Fetched data:', searchData); // Log fetched data
      const fuseOptions = {
        keys: ['displayName', 'occupation', 'keywords', 'expertise_areas'],
        includeScore: true,
        includeMatches: true,
        threshold: 0.4,
      };

      const fuse = new Fuse(searchData, fuseOptions);
      const searchResults = searchQuery.trim() !== '' ? fuse.search(searchQuery) : [];
      const matchedDataBySearch = searchResults.map((result) => result?.item);

      const fuseOptionsLocation = {
        keys: ['city', 'address'],
        includeScore: true,
        includeMatches: true,
        threshold: 0.4,
      };

      const fuseLocation = new Fuse(searchData, fuseOptionsLocation);
      const locationSearchResults = locationQuery.trim() !== '' ? fuseLocation.search(locationQuery) : [];
      const matchedDataByLocation = locationSearchResults.map((result) => result?.item);

      const filteredData = searchData.filter((expert) =>
        filterByPreferredCommunicationMethod(expert, selectedCommunicationMethod)
      );

      let sortedData = filteredData;

      if (sortOption === 'rating') {
        sortedData = sortedData.sort(sortByRating);
      } else if (sortOption === 'experience') {
        sortedData = sortedData.sort(sortByExperience);
      } else if (sortOption === 'fastestResponse' || jobSuccess === 'fastestResponse') {
        sortedData = sortedData.sort(sortByFastestResponse);
      }

      const combinedResults = [
        ...sortedData,
        ...matchedDataBySearch,
        ...matchedDataByLocation,
      ];
      
      // Create a new Set from the combinedResults array
      const uniqueResults = new Set(combinedResults.map(JSON.stringify));
      
      // Convert the Set back to an array
      const filteredResults = Array.from(uniqueResults, JSON.parse);
      
      setFilteredResults(filteredResults);
      setOtherUserUID(userUIDs);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
    console.log('Search completed.'); // Log end of search
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const sortByRating = (a, b) => b.rating - a.rating;

  const sortByExperience = (a, b) => parseFloat(b.yearsOfExperience) - parseFloat(a.yearsOfExperience);

  const sortByFastestResponse = (a, b) => {
    const responseRateOrder = ['Within An Hour', 'Within One Day', 'More Than A Day'];
    const aResponseRate = a.responseRate;
    const bResponseRate = b.responseRate;

    const aIndex = responseRateOrder.indexOf(aResponseRate);
    const bIndex = responseRateOrder.indexOf(bResponseRate);

    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  };

  const filterByPreferredCommunicationMethod = (expert, selectedMethod) => {
    const { call, email, video } = expert.selectedOptions;

    switch (selectedMethod) {
      case 'chat':
        return call || email || video;
      case 'video':
        return video;
      case 'email':
        return email;
      case 'all':
        return call && email && video;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (!isFromSearchBar) {
      handleSearch();
    }
  }, [isFromSearchBar]);

  useEffect(() => {
    if (isFromSearchBar && results.length > 0) {
      setFilteredResults(results);
    }
  }, [isFromSearchBar, results]);


  return (
    <div className={`search-results ${isOpen ? 'open' : ''}`} ref={resultsRef}>
      <button className="btnimageclose position-absolute top-0 end-0 listcard pe-1" aria-label="Close" onClick={onClose}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="black" strokeWidth="1.5" className={`w-4 h-4 smaller-svgcard`}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
  
      <div className="row">
        <div className="col-md-3 scrollable-col">
          <div className="advanced-search">
            <h2>Search</h2>
            <div className="form-group">
              <input
                type="search"
                className="mobile-search-desktop"
                placeholder="Search for Experts"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
  
            <div className="form-group">
            <label className="jobSuccessSelect mb-1">Job Success:</label>
            <ul className="listcard">
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="anyJobSuccess" name="jobSuccess"  />
    <label className="form-check-label label-with-svg" htmlFor="anyJobSuccess">
      <FaThumbsUp color="#9926f0" size={25} />
      <span>Most Helpful</span>
    </label>
  </li>
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="jobSuccess80" name="jobSuccess"  />
    <label className="form-check-label label-with-svg" htmlFor="jobSuccess80">
      <FaTrophy color="#9926f0" size={25} />
      <span>Rising Tio</span>
    </label>
  </li>
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="jobSuccess90" name="jobSuccess" checked={jobSuccess === 'fastestResponse'} onChange={() => setJobSuccess('fastestResponse')} />
    <label className="form-check-label label-with-svg" htmlFor="jobSuccess90">
      <FaRocket color="#9926f0" size={25} />
      <span>Fastest Response</span>
    </label>
  </li>
</ul>
          </div>
  
            <div className="form-group mb-1">
              <label htmlFor="sortSelect">Sort By:</label>
              <select
                className="form-select mb-2"
                id="sortSelect"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="relevance">Relevance</option>
                <option value="rating">Rating</option>
                <option value="experience">Experience</option>
                <option value="fastestResponse">Fastest Response</option>
              </select>
            </div>

            <div className="form-group mb-1">
              <label htmlFor="communicationMethodSelect">Preferred Communication Method:</label>
              <select
                className="form-select mb-2"
                id="communicationMethodSelect"
                value={selectedCommunicationMethod}
                onChange={(e) => setSelectedCommunicationMethod(e.target.value)}
              >
                <option value="chat">Chat</option>
                <option value="video">Video Call</option>
                <option value="email">Email</option>
                <option value="all">All</option>
              </select>
            </div>
  
            <div className="form-group mb-2">
              <label htmlFor="locationInput">Location:</label>
              <input
                type="text"
                className="form-control"
                id="locationInput"
                placeholder="Enter location"
                value={locationQuery}
                onChange={(e) => setLocationQuery(e.target.value)}
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="skillsInput">Skills:</label>
              <input
                type="text"
                className="form-control"
                id="skillsInput"
                placeholder="Enter skills tags"
                value={skillsQuery}
                onChange={(e) => setSkillsQuery(e.target.value)}
              />
            </div>
  
            <div className="row">
              <div className="row justify-content-space-between">
                <button className="listbutton signup-button" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
  
        <div className="col-md-9 pe-3 pt-3 cardresults">
      <div className="search-results-container" style={{ maxHeight: '70vh', overflowY: 'auto' }}>

      {filteredResults.length > 0 ? (
  <div className="row ">
    {filteredResults.map((result, index) => (
      <div className="col-md-12" key={index}>
        <SearchResultCard result={result} onClose={onClose} otherUserUID={result.otherUserUID} />
      </div>
    ))}
  </div>
) : (
  <div className="row">
    <div className="col-md-12 text-center mt-5 mb-5">
      <h1>Sorry</h1>
      <p>{noResultsMessage}</p>
    </div>
  </div>
)}


      </div>
      <Pagination />
    </div>
  </div>
    </div>
  );
};

export default SearchResults;