
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged,  GoogleAuthProvider, FacebookAuthProvider, sendEmailVerification, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getFirestore, collection, doc, limit, setDoc, orderBy, getDoc, updateDoc, query, where, getDocs, serverTimestamp, arrayUnion, onSnapshot, writeBatch, addDoc  } from "firebase/firestore";
import { getStorage } from "firebase/storage"; // Import Firebase Storage
import { getMessaging, getToken, } from "firebase/messaging";


const firebaseConfig = {
  apiKey: "AIzaSyA0th4hz_oJj6lltCWw9o_AYEst6Vffe0A",
  authDomain: "tioassist.io",
  databaseURL: "https://tio-login-auth-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tio-login-auth",
  storageBucket: "gs://tio-login-auth.appspot.com/",
  messagingSenderId: "431458592881",
  appId: "1:431458592881:web:5fea9314009a5265e7d3d0",
  measurementId: "G-4F1VXHJTY0"
};

const app = initializeApp(firebaseConfig);  



export const messaging = getMessaging(app);

export const db = getFirestore(app);


export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app); 

export {orderBy,limit, onAuthStateChanged,collection, doc, setDoc, serverTimestamp, arrayUnion, onSnapshot, updateDoc, query, where, getDocs, getDoc, writeBatch, addDoc  };

export async function getFCMToken() {
  try {
    let currentToken = await getToken(messaging, { vapidKey: 'BLYod3GIFPC0yPhoteAGV6NXw9qRlHjeUt92dToKTlFW2qqosY3c4NUjyoeElFFwZbIkXCKYSPGe39o664kOQwg' });

    if (!currentToken) {
      console.log("No FCM token available. Generating a new one...");

      // Request permission for notifications and get the new token
      await requestPermission();
      currentToken = await getToken(messaging, { vapidKey: 'BLYod3GIFPC0yPhoteAGV6NXw9qRlHjeUt92dToKTlFW2qqosY3c4NUjyoeElFFwZbIkXCKYSPGe39o664kOQwg' });
    }

    return currentToken;
  } catch (error) {
    console.error("Error getting FCM token:", error);
    return null;
  }
}

export function requestPermission() {
  console.log('Requesting permission...');
  return Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted...');
    } else {
      console.log("Do not have permissions");
    }
  });
}

export async function storeFCMToken(userUid, token) {
  try {
    console.log("Storing FCM token for user:", userUid);
    const userProfilesCollection = collection(firestore, "userProfiles");
    const expertApplicationsCollection = collection(firestore, "expertApplications");

    // Check if the user is in the expertApplications collection
    const expertApplicationDoc = doc(expertApplicationsCollection, userUid);
    const expertApplicationSnap = await getDoc(expertApplicationDoc);

    if (expertApplicationSnap.exists()) {
      // User is in the expertApplications collection
      const userDocRef = doc(expertApplicationsCollection, userUid);
      await setDoc(userDocRef, { fcmToken: token }, { merge: true }); // Merge with existing data if it exists
    } else {
      // User is in the userProfiles collection
      const userDocRef = doc(userProfilesCollection, userUid);
      await setDoc(userDocRef, { fcmToken: token }, { merge: true }); // Merge with existing data if it exists
    }

    console.log("FCM token stored successfully for user:", userUid);
  } catch (error) {
    console.error("Error storing FCM token for user:", userUid, error);
  }
}

onAuthStateChanged(auth, async (user) => {
  if (user) {
    console.log("User is signed in:", user);

    // Get FCM token
    const currentToken = await getFCMToken();
    console.log("Current FCM token:", currentToken);

    if (currentToken) {
      console.log("FCM token:", currentToken);

      // Store FCM token in Firestore
      storeFCMToken(user.uid, currentToken);
    } else {
      console.log("No FCM token available.");
    }

    const userProfilesCollection = collection(firestore, "userProfiles");
    const expertApplicationsCollection = collection(firestore, "expertApplications");
    const userProfileDoc = doc(userProfilesCollection, user.uid);
    const expertApplicationDoc = doc(expertApplicationsCollection, user.uid);

    const userProfileSnap = await getDoc(userProfileDoc);
    const expertApplicationSnap = await getDoc(expertApplicationDoc);

    if (!userProfileSnap.exists() && !expertApplicationSnap.exists()) {
      createUserProfile(user);
    }
  } else {
    console.log("User is signed out.");
  }
});


function generateRandomURL(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomURL = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomURL += characters.charAt(randomIndex);
  }

  return randomURL;
}

async function generateUniqueRandomURL(collectionRef) {
  let randomURL;
  let isUnique = false;

  while (!isUnique) {
    randomURL = generateRandomURL(8); // You can adjust the length as needed
    const docRef = doc(collectionRef, randomURL);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      isUnique = true;
    }
  }

  return randomURL;
}

async function createUserProfile(user) {
  const displayName = user.displayName || '';
  const firstName = displayName.split(' ')[0] || '';
  const lastName = displayName.split(' ')[1] || '';

  const userProfileData = {
    firstName,
    lastName,
    displayName,
    points: 100,
    referralSubmitted: false,
    hasSeenWelcomeMessage: false,
  };
  
  const userProfilesCollection = collection(firestore, "userProfiles");

  if (!user.customURL) {
    // Generate a unique random custom URL
    const uniqueRandomURL = await generateUniqueRandomURL(userProfilesCollection);
    userProfileData.customURL = uniqueRandomURL;
  } else {
    userProfileData.customURL = user.customURL;
  }

  // Generate a unique referral code for the user using their name and the current timestamp
  const referralCode = generateReferralCode(user.displayName);
  userProfileData.referralCode = referralCode;

  const userDocRef = doc(userProfilesCollection, user.uid);

  try {
    await setDoc(userDocRef, userProfileData);
    console.log("User profile created with custom URL and referral code:", userProfileData.customURL, referralCode);
  } catch (error) {
    console.error("Error creating user profile:", error);
  }
}


function generateReferralCode() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let referralCode = '';

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    referralCode += characters.charAt(randomIndex);
  }

  return referralCode;
}


export default app;
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();

export function isPasswordValid(password) {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

export const sendVerificationEmail = async () => {
  try {
    const user = auth.currentUser;

    if (user && !user.emailVerified) {
      await sendEmailVerification(user);
      console.log('Verification email sent successfully');
    } else {
      console.log('User is already verified or user is not available.');
    }
  } catch (error) {
    console.error('Error sending verification email:', error);
  }
};

setPersistence(auth, browserLocalPersistence);