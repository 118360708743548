import React from 'react';
import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const StarRating = ({ rating }) => {
  return (
    <div className="expert-rating-stars">
      {/* Render the second row of stars */}
      <div className="expert-star-background">
        {[...Array(5)].map((_, index) => {
          return (
            <span key={index} className="expert-star">
              <FaStar />
            </span>
          );
        })}
      </div>

      {/* Render the first row of stars based on the rating */}
      <div className="expert-star-foreground">
        {[...Array(5)].map((_, index) => {
          const ratingValue = index + 1;
          const isHalfStar = rating >= ratingValue - 0.5 && rating < ratingValue;
          const isFullStar = rating >= ratingValue;

          return (
            <span
              key={ratingValue}
              className={`expert-star  ${isFullStar ? 'expert-filled' : ''} ${isHalfStar ? 'expert-half' : ''}`}
            >
              {isHalfStar ? <FaStarHalfAlt /> : isFullStar ? <FaStar /> : <FaStar />}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default StarRating;
