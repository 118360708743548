import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function DropdownMenu({ isOpen, handleDropdownItemClick, handleLogout, isApplicationApproved, closeDropdown, hasUnreadMessages }) {
  const profileLink = isApplicationApproved ? "/expertprofile" : "/profile";
  const settingsLink = isApplicationApproved ? "/expertsettings" : "/settings"; 

  const [activeLink, setActiveLink] = useState('');


  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // Function to handle click on link
  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsDropdownOpen(false);
    setIsDropdownOpenMenu(false);
  };

  
  const containerRef = useRef(null);

  const onItemClick = () => {
    closeDropdown(); // Close the dropdown
    handleDropdownItemClick(); // Call the original handler
  };

  const handleDocumentClick = (event) => {
    if (
      isOpen &&
      containerRef.current &&
      !containerRef.current.contains(event.target)
    ) {
      closeDropdown(); // Close the dropdown if clicked outside
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isOpen]);
  return (
    <div ref={containerRef} className={`dropdown-menu-container navbar-nav ${isOpen ? 'open' : ''}`}>




      <li className={`dropdown-item-menu no-underline gradient-underline mt-2 mb-2 ${activeLink === profileLink ? 'active' : ''}`}>
        <Link to={profileLink}  className="nav-link-menu n d-flex align-items-center "  onClick={onItemClick}>
        <div className='boldp' tyle={{ marginRight: '5px' }}>Profile</div>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#9926f0" stopOpacity="1" />
      <stop offset="100%" stopColor="#d122e3" stopOpacity="1" />
    </linearGradient>
  </defs>
  <path
d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"       stroke="url(#gradient)"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
  />
</svg>
      
          </div>
        </Link>
      </li>




<li className={`dropdown-item-menu no-underline gradient-underline mt-2 mb-2 ${activeLink === settingsLink ? 'active' : ''}`}>
  <Link to={settingsLink} className="nav-link-menu d-flex align-items-center" onClick={onItemClick}>
    <div className='d-flex align-items-center'>
 
    <div className='boldp' style={{ marginRight: '5px' }}>Settings</div>
        <div className='d-flex'>

      <svg 
        className="svg-list-icon" 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24" 
        strokeWidth={1} 
        stroke="currentColor" 
        height="25" 
        width="25" 
        style={{ marginRight: '10px' }}
      >
        <defs>
          <linearGradient id="gradient-settings" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#9926f0" stopOpacity="1" />
            <stop offset="100%" stopColor="#d122e3" stopOpacity="1" />
          </linearGradient>
        </defs>
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" 
          stroke="url(#gradient-settings)"
        />
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" 
          stroke="url(#gradient-settings)"
        />
      </svg>
      </div>
      </div>

  </Link>
</li>


<li className={`dropdown-item-menu no-underline gradient-underline mt-2 mb-2 ${activeLink === '/chat' || activeLink === '/chat' ? 'active' : ''}`}>
        <Link to="/chat"  className="nav-link-menu n d-flex"  onClick={onItemClick}>

        <div className='boldp' style={{ marginRight: '5px' }}>Messages</div>
        <div className='d-flex'>
        <div className={`svg-wrapper ${hasUnreadMessages ? 'unread-svg-border-message' : ''}`}>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#9926f0" stopOpacity="1" />
      <stop offset="100%" stopColor="#d122e3" stopOpacity="1" />
    </linearGradient>
  </defs>
  <path
      d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
      stroke="url(#gradient)"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
  />
</svg>
      </div>
          </div>
        </Link>
      </li>



      <li className="dropdown-item-menu no-underline gradient-underline mt-2 mb-2">
        <Link className="nav-link-menu n d-flex"   onClick={handleLogout}>
        <div className='boldp' style={{ marginRight: '5px' }}>Logout</div>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" stopColor="#9926f0" stopOpacity="1" />
      <stop offset="100%" stopColor="#d122e3" stopOpacity="1" />
    </linearGradient>
  </defs>
  <path
    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
    stroke="url(#gradient)"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none"
  />
</svg>
      
          </div>
        </Link>
      </li>

    </div>
  );
}

export default DropdownMenu;


