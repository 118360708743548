import React, { useState, useEffect } from 'react';
import IosPopup from './IosPopup';

const InstallPromptButtonAlways = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showIosPopup, setShowIosPopup] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  const handleInstallClick = () => {
    if (isIos()) {
      setShowIosPopup(true);
    } else if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    } else {
      // If deferredPrompt is not available, the app might already be installed
      // or the browser doesn't support installation
      alert('The app is either already installed or installation is not supported in this browser.');
    }
  };

  return (
    <>
      <button onClick={handleInstallClick} className="install-button cartoon-shadow-secondary">
        <img
          src={`/images/arts/TIO.webp`}
          alt="TIO Logo"
          className="install-button-logo"
        />
        <span className="install-button-text">Install Tio</span>
      </button>
      <IosPopup showPopup={showIosPopup} setShowPopup={setShowIosPopup} />
    </>
  );
};

export default InstallPromptButtonAlways;