import React from 'react';
import { Fade as Hamburger } from 'hamburger-react';

const DESKTOP_BREAKPOINT = 1024;

const isDesktop = window.innerWidth >= DESKTOP_BREAKPOINT;
const isMobile = window.innerWidth < DESKTOP_BREAKPOINT;

const NavbarSkeleton = () => {
  return (
    <nav className="navbar d-flex navbar-expand-lg navbar-dark menu shadow fixed-top">
      <div className="container custom-navbar-container position-relative">
      <a className="navbar-brand" href="https://www.TioAssist.io/" aria-label="TioAssist Home">
          <img
            src="/images/logo/LogoTio.webp"
            alt="TioAssist logo"
            width="990"
            height="275"
          />
        </a>



              </div>
    </nav>
  );
};

export default NavbarSkeleton;