import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { PopupContext } from '../boxes/PopupContext';
import { auth, firestore, collection, doc, getDoc } from '../firebase';
import InstallPromptButtonAlways from './InstallPromptButtonAlways';

const Footer = () => {
  const [showFooter, setShowFooter] = useState(true);
  const { pathname } = useLocation();
  const { handleLoginButtonClick } = useContext(PopupContext);
  const [user, setUser] = useState(null);
  const [isUserProfile, setIsUserProfile] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const userProfilesCollection = collection(firestore, 'userProfiles');
        const userProfileDoc = doc(userProfilesCollection, user.uid);
        const userProfileSnapshot = await getDoc(userProfileDoc);
        if (userProfileSnapshot.exists()) {          setIsUserProfile(true);
        } else {
          setIsUserProfile(false);
        }
      } else {
        setUser(null);
        setIsUserProfile(false);
      }
    });

    return () => unsubscribe();
  }, []);


  useEffect(() => {
    const isInChatRoute = pathname.includes('/chat');
    const isMobile = window.matchMedia('(max-width: 768px)').matches;

    // Hide footer if in the chat route on a mobile device
    if (isInChatRoute && isMobile) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [pathname]);

  if (!showFooter) {
    return null; // Return null when footer should be hidden
  }

  return (
    <>
      {showFooter && (
        <div>


      <footer className="footers">
        <div className="container">
          <div className="row footer-row">
        
            <div className="col-12 col-sm-6 col-lg-2 mb-4">
              <h5  role="heading" aria-level="1" className="text-capitalize fw-bold" style={{ color: '#ffffff' }}>About Us</h5>
              <ul className="list-inline campany-list">
                <li>  <Link to="/OurMission">Our Mission</Link></li>
                <li><Link to="/OurTeam">Our Team</Link></li>
                <li><Link to="/JoinUs">Join Us</Link></li>
                <li><Link to="/PressAndMedia">Press and Media</Link></li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 mb-4">
              <h5  role="heading" aria-level="1" className="text-capitalize fw-bold" style={{ color: '#ffffff' }}>Support</h5>
              <ul className="list-inline campany-list">
                <li><Link to="/FAQ">FAQ</Link></li>
                <li><Link to="/HelpCenter">Help Center</Link></li>
                <li><Link to="/ContactUs">Contact Us</Link></li>
                <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-lg-2 mb-4">
              <h5  role="heading" aria-level="1" className="text-capitalize fw-bold" style={{ color: '#ffffff' }}>Useful Links</h5>
              <ul className="list-inline campany-list">
              {user ? (
      <li>
        <Link to={isUserProfile ? '/profile' : '/expertprofile'}>Account</Link>
      </li>
    ) : (
      <li onClick={handleLoginButtonClick}>
        <a href="#">Create an Account</a>
      </li>
    )}
                {user ? (
      <li>
        <Link to={isUserProfile ? '/settings' : '/expertsettings'}>Referral</Link>
      </li>
    ) : (
      <li><Link to="/Referral">Referral</Link></li>
    )}
                <li><a href="/Blog  ">Blog</a></li>
                <li><a href="/ExpertResources">Expert Resources</a></li>
              </ul>
            </div>
            <div className="col-md-12 col-lg-4 ">
              <div className="footers-logo">  
                <a className="navbar-brand" href="#">TioAssist.io</a>
                <p>TioAssist.io is the leader in bringing together AI and People. Please contact us if you have any questions.</p>
              </div>
              <div className="social-links">
                <a href="#" aria-label="Facebook"><i className="fab fa-facebook" aria-hidden="true"></i></a>
                <a href="#" aria-label="Twitter"><i className="fab fa-x-twitter" aria-hidden="true"></i></a>

                <a href="#" aria-label="Telegram"><i className="fab fa-telegram" aria-hidden="true"></i></a>
                <a href="#" aria-label="LinkedIn"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
                <a href="#" aria-label="Instagram"><i className="fab fa-instagram" aria-hidden="true"></i></a>
              </div>
            </div>
          </div>

        </div>
        <div className="copyrights">
        <div className="container">
          <div className='img-blog-mobile'>
        <InstallPromptButtonAlways/>
        </div>
          <p>&copy; Copyrights TioAssist.io. All rights reserved.</p>
          <div className="credits">
            Designed by Tio | Created with Love
          </div>

        </div>
        </div>
        </footer>
     </div>
      )}
    </>
  );
};

export default Footer;
