import React, { useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import useMobile from './useMobile';
import useIpad from './useIpad';
import { PopupContext } from '../boxes/PopupContext';
import CategoryIcons from './CategoryIcons';
import WelcomePopup from './WelcomePopup';
import { auth, doc, firestore, getDoc, updateDoc, setDoc } from '../firebase';

function Homesection() {
  const isMobile = useMobile();
  const isIpad = useIpad();
  const isMobileOrIpad = isMobile || isIpad;
  const { handleLoginButtonClick } = useContext(PopupContext);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        setDisplayName(user.displayName || 'there');
        const userDocRef = doc(firestore, "userProfiles", user.uid);
        
        try {
          const userDoc = await getDoc(userDocRef);
          
          if (userDoc.exists()) {
            // User exists in userProfiles
            if (!userDoc.data().hasSeenWelcomeMessage) {
              setShowWelcomePopup(true);
            }
          } else {
            // User doesn't exist in userProfiles, don't show welcome popup
            console.log("User not found in userProfiles");
          }
        } catch (error) {
          console.error("Error checking user document:", error);
        }
      }
    });
    
    return () => unsubscribe();
  }, []);

  const handleChat = () => {
    if (isAuthenticated === null) {
      return;
    }
    if (isAuthenticated) {
      navigate('/chatbot');
    } else {
      handleLoginButtonClick();
    }
  };

  const handleCloseWelcomePopup = async () => {
    setShowWelcomePopup(false);
    if (auth.currentUser) {
      const userDocRef = doc(firestore, "userProfiles", auth.currentUser.uid);
      await updateDoc(userDocRef, { hasSeenWelcomeMessage: true });
    }
  };

  return (
    <section id="Home" className="intro-section">
      {showWelcomePopup && (
        <WelcomePopup 
          onClose={handleCloseWelcomePopup} 
          displayName={displayName}
        />
      )}
  <div className="container">
    
        <div className="  text-white justify-content-space-evenly">
          <div className="col-md-4 col-6 intros text-start">
            <div className='position-relative '>
            <h1 className="display-3">
              <span className="display-2--intro">Hey! I'm <span style={{ textTransform: 'capitalize' }}>
                
                <br></br>Tio</span><span style={{ textTransform: 'capitalize' }}>Assist</span>.<span style={{ textTransform: 'lowercase' }}>io</span></span>
           
                </h1>
                <svg xmlns='http://www.w3.org/2000/svg' className="scribble" viewBox='0 0 1418 125'>
  <defs>
    <linearGradient id="SvgjsLinearGradient1007" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop stopColor="hsl(105, 69%, 50%)" offset="0"></stop>
      <stop stopColor="hsl(105, 69%, 60%)" offset="1"></stop>
    </linearGradient>
  </defs>
  <path d='M1212.41 5.51c3.05 12.87-22.36 11.93-30.26 15.68-94.32 20.51-269.09 32.42-365.48 37.51-77.91 3.82-155.66 9.93-233.67 11.67-57.49 2.56-115.05-.19-172.57 1.58-121.28.91-243.17 1.88-363.69-13.33-12.51-2.64-25.8-2.92-37.77-7.45-30.66-21.42 26.02-21.53 38.52-19.26 359.95 29.05 364.68 27.36 638.24 17.85 121-3.78 241.22-19.21 426.76-41.46 4.72-.65 9.18 3.56 8.45 8.36a941.74 941.74 0 0 0 54.29-9.21c9.33-2.33 18.7-4.56 27.95-7.19a7.59 7.59 0 0 1 9.23 5.24Z'

fill='url(#SvgjsLinearGradient1007)'></path>
</svg>
{!isMobileOrIpad && (
       <>
             <br></br> 
             <div className="text-white display-2--description lh-base white">
  With <i className="italic-font">Verified Expertise,</i> <br />
  and the power of <i className="italic-font">AI</i>
</div>             </>
             )}

          
            </div>
           
          </div>
          
          <div className="col-md-4 col-4 text-end ">
              <div className="image-container">
              <img
  src="/images/arts/TIOS.webp"
  alt="TIO illustration"
  className="img-fluid6"
  width="974"
  height="778"
  decoding="async"
  data-src="/images/arts/TIOS.webp"
/>
<link rel="preload" href="/images/arts/TIOS.webp" as="image" />

              </div>
          </div>
          
        </div>

{isMobileOrIpad && (
       <>
             <br></br> <div className=" text-white display-2--description  white"> With <i className='italic-font'>Verified Expertise,</i> <br></br> and the power of  <i className='italic-font'>AI</i></div>
             </>
             )}
        {!isMobileOrIpad && (
              <>
   <div className="parent-container">
  <div className="display-column">
    <span className="tio-chat italic-font" >
    Do your best thing with TIO
    </span>
    <div className="d-flex" style={{ width: '100%' }}>
      <button
        type="button"
        id="CTAButton"
        className="btn-cta-white btn-width mt-3"
        onClick={handleChat}
        disabled={isAuthenticated === null}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        Let's Chat!
        <span>
          <i className="fas fa-arrow-right ps-3"></i>
        </span>
      </button>
    </div>
    <span className="car-chat tio-chat mb-0" >
        <i>Find experts in...</i>
   
    </span>
  </div>
</div>
              </>
            )}
        {isMobileOrIpad && (
          <>
          <div className='text-center mt-2'>
          <span className="tio-chat italic-font" >
    Do your best thing with TIO
    </span>
            </div>
            <div className='img-blog-mobile'>
                <button
                  type="button"
                  id="tryMeButton"
                  className="  btn-cta-white  mt-3 "
                  onClick={handleChat}
                  disabled={isAuthenticated === null}
                >
                  Let's Chat!
                  <span><i className="fas fa-arrow-right ps-3"></i></span>
                </button>
            </div>
            <div className='car-chat tio-chat mb-0'>
            <span className="" >
        Find experts in...
   
    </span>
    </div>
   
          </>
        )}
      <CategoryIcons/>
      </div>
 

      <div className="wave-container">
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 280" className="wave-svg">
    <path fill="#ffffff" fillOpacity="1" d="M0,160L48,176C96,192,192,224,288,208C384,192,480,128,576,133.3C672,139,768,213,864,202.7C960,192,1056,96,1152,74.7C1248,53,1344,107,1392,133.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg>
<div className='white-overlay'>
  </div>  </div>
    </section>
  );
};

export default Homesection;
