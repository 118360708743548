import React, { useState, useEffect } from 'react';
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

function EmailBox({ onGoBack }) {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  const actionCodeSettings = {
    url: 'https://www.tioassist.io/',
    handleCodeInApp: true,
  };

  const auth = getAuth();

  const handleSignup = () => {
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem('emailForSignIn', email);
        setMessage('Email sent successfully. Please check your inbox');
        setMessageColor('green');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/invalid-email') {
          setMessage('Invalid email format. Please use a different method to sign up.');
        } else if (errorCode === 'auth/quota-exceeded') {
            setMessage("Hmm, something went wrong. Please try a different login method while our Tio's fix your problem.");

        } else {
          setMessage(`Error (${errorCode}): ${errorMessage}`);
        }
        setMessageColor('red');
      });
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSignup();
      const submitButton = document.getElementById('btnSignup');
      if (submitButton) {
        submitButton.focus();
      }
    }
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkIsMobile();

    // Add event listener for window resize to update isMobile
    window.addEventListener('resize', checkIsMobile);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return (
    <div className="login-box" id="loginBox">
    <div >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="black"
            strokeWidth={1.5}
            onClick={onGoBack}
            stroke="currentColor"
            className="image-go-back-svg "
            viewBox="0 -960 960 960">
            <path d="m313-440 196 196q12 12 11.5 28T508-188q-12 11-28 11.5T452-188L188-452q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l264-264q11-11 27.5-11t28.5 11q12 12 12 28.5T508-715L313-520h447q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440H313Z"/>
              </svg>
      </div>
<div className='login-container'>
 <div className="login-content">

          <div className="login-image">
          <img src={`/images/Tios/Earning-Tio.webp`} alt="TIO illustration" className="img-fluid-tio" />
          </div>
      
<div className='login-context'>
<div className="log-in-text">
    <h4 className="h3 mb-3">Login or sign up</h4>
  </div>
<h6 className="log-in-text-closing mb-4">
<p className="fw-light">The most sensible way to use AI and get the results you need!</p>
</h6>
        {isMobile && (
    <img
    src={`/images/logo/Logo-dark.webp`}
    alt="TioAssist logo"
    width="100"
    style={{
      position: 'absolute',
      top: 15,
      left: 10,
    }}
  />
)}

        <form id="signupForm">
          <div className="signup-form">
          <div className="col-lg-12 mb-3 mt-5">
              <input
                type="email"
                placeholder="email address"
                id="input-Email"
                className="  form-control form-control-lg borderradius "
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyPress}S
              />
            </div>

            <div className="col-lg-12 mb-3 mt-5" 
            style={{ paddingBottom: '15px' }}>
              <button
                type="button"
                className="signup-button"
            
                id="btnSignup"
                onClick={handleSignup}
              >
                Submit 
              </button>
            </div>
            {message && <p className="fw-light" style={{ color: messageColor }}>{message}</p>}
          </div>
        </form>
           <div className="terms-container">
     <div role="separator" className="terms-divider">
<div className="terms">
<p className="fw-light" style={{ fontSize: '14px' }}>
  By continuing, you agree to our <a href="URL_TO_TERMS_OF_USE" target="_blank" rel="noopener">Terms of Use</a>.
  Read our <a href="URL_TO_PRIVACY_POLICY" target="_blank" rel="noopener">Privacy Policy</a>.
</p>
</div>
</div>
</div>
        </div>
  
  </div>
</div>
</div>
  );
}

export default EmailBox;
