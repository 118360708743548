import React, { useContext, createContext, useState, useEffect, useRef } from 'react';
import LoginBox from './LoginBox';
import SignUpBox from './SignUpBox';
import Navbar from '../components/Navbar';
import PasswordBox from './PasswordBox';
import EmailBox from './EmailBox';
import MobileNavbar from './MobileNavbar';
import { useNavigate } from 'react-router-dom';

export const PopupContext = createContext({
  showLoginBox: false,
  showSignUpBox: false,
  showPasswordBox: false,
  showEmailBox: false,
  closeAllPopups: () => {},
  handleLoginButtonClick: () => {},
  showSignUp: () => {},
  showPassword: () => {},
  showEmail: () => {},
});

export const PopupProvider = ({ children }) => {
  const [showLoginBox, setShowLoginBox] = useState(false);
  const [showSignUpBox, setShowSignUpBox] = useState(false);
  const [showPasswordBox, setShowPasswordBox] = useState(false);
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();

  const popupRef = useRef(null);

  const closeAllPopups = () => {
    setShowLoginBox(false);
    setShowSignUpBox(false);
    setShowPasswordBox(false);
    setShowEmailBox(false);
    setIsPopupOpen(false);
  };

  const showSignUp = () => {
    closeAllPopups();
    setShowSignUpBox(true);
  };

  const showPassword = () => {
    closeAllPopups();
    setShowPasswordBox(true);
  };

  const showEmail = () => {
    closeAllPopups();
    setShowEmailBox(true);
  };

  const handleLoginButtonClick = () => {
    closeAllPopups();
    setShowLoginBox(true);
  };



  // Check if the screen width is less than or equal to 768px (mobile devices)
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // Disable scrolling on mobile devices when a popup is open
    if (isMobile) {
      document.body.style.overflow = showLoginBox || showSignUpBox || showPasswordBox || showEmailBox
        ? 'hidden'
        : 'auto';
    }

    return () => {
      // Enable scrolling when popups are closed
      if (isMobile) {
        document.body.style.overflow = 'auto';
      }
    };
  }, [showLoginBox, showSignUpBox, showPasswordBox, showEmailBox, isMobile]);

  const contextValue = {
    showLoginBox,
    showSignUpBox,
    showPasswordBox,
    showEmailBox,
    closeAllPopups,
    handleLoginButtonClick,
    showSignUp,
    showPassword,
    showEmail,
  };

  return (
    <PopupContext.Provider value={contextValue}>
      <div>
        {showLoginBox || showSignUpBox || showPasswordBox || showEmailBox ? (
          <div>
            <div className="blurred-background"></div>
          </div>
        ) : null}
        <div ref={popupRef}>
          {showLoginBox && (
            <LoginBox
              showSignUpBox={showSignUp}
              showPasswordBox={showPassword}
              showEmailBox={showEmail}
              onLoginSuccess={closeAllPopups}
            />
          )}
          {showSignUpBox && (
            <SignUpBox
              onGoBack={handleLoginButtonClick}
              onSignUpSuccess={() => {}}
              showPasswordBox={showPassword}
              closeAllPopups={closeAllPopups}
            />
          )}
          {showEmailBox && (
            <EmailBox
              onGoBack={handleLoginButtonClick}
              onSignUpSuccess={() => {}}
              showEmailBox={showEmailBox}
            />
          )}
          {showPasswordBox && <PasswordBox onGoBack={handleLoginButtonClick} />}
        </div>
        {children}
      </div>
    </PopupContext.Provider>
  );
};
