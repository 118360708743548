import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const ServicesDropdown = ({ activeLink, handleDropdownItemClick, isDropdownOpenMenu, navbarRef }) => {
  const dropdownRefService = useRef(null);
  const [isMouseInside, setIsMouseInside] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefService.current &&
        !dropdownRefService.current.contains(event.target) &&
        !navbarRef.current.contains(event.target) &&
        isDropdownOpenMenu &&
        !isMouseInside
      ) {
        handleDropdownItemClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleDropdownItemClick, isDropdownOpenMenu, isMouseInside, navbarRef]);

  const handleMouseEnter = () => {
    setIsMouseInside(true);
  };

  const handleMouseLeave = () => {
    setIsMouseInside(false);
    if (isDropdownOpenMenu) {
      handleDropdownItemClick();
    }
  };
  return (
    <div
      ref={dropdownRefService}
      className={`dropdown-menu-list navbar-nav ${isDropdownOpenMenu ? 'open' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >  <div className="row">
        <ul className="col-3">
          
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/Pricing' || activeLink === '/Pricing' ? 'active' : ''}`}>
        <Link to="/Pricing" className="nav-link-menu" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M856-390 570-104q-12 12-27 18t-30 6q-15 0-30-6t-27-18L103-457q-11-11-17-25.5T80-513v-287q0-33 23.5-56.5T160-880h287q16 0 31 6.5t26 17.5l352 353q12 12 17.5 27t5.5 30q0 15-5.5 29.5T856-390ZM513-160l286-286-353-354H160v286l353 354ZM260-640q25 0 42.5-17.5T320-700q0-25-17.5-42.5T260-760q-25 0-42.5 17.5T200-700q0 25 17.5 42.5T260-640Zm220 160Z" fill="url(#gradient)"/>
</svg>
<div className='bot-details'>
          <h5>Price</h5>
          <p>Pricing and more</p>
          </div>
          </div>
        </Link>
      </li>
      </ul>

      <ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/FAQ' || activeLink === '/FAQ' ? 'active' : ''}`}>
        <Link to="/FAQ" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >
        <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
            <path d="M560-360q17 0 29.5-12.5T602-402q0-17-12.5-29.5T560-444q-17 0-29.5 12.5T518-402q0 17 12.5 29.5T560-360Zm0-128q11 0 20.5-8t11.5-21q2-12 8.5-22t23.5-27q30-30 40-48.5t10-43.5q0-45-31.5-73.5T560-760q-33 0-60 15t-43 43q-6 10-1 21t17 16q11 5 21.5 1t17.5-14q9-13 21-19.5t27-6.5q24 0 39 13.5t15 36.5q0 14-8 26.5T578-596q-29 25-37 38.5T531-518q-1 12 7.5 21t21.5 9ZM320-240q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320Zm0-80h480v-480H320v480ZM160-80q-33 0-56.5-23.5T80-160v-520q0-17 11.5-28.5T120-720q17 0 28.5 11.5T160-680v520h520q17 0 28.5 11.5T720-120q0 17-11.5 28.5T680-80H160Zm160-720v480-480Z"fill="url(#gradient)"/>
            </svg> 
                   <div className='bot-details'>
          <h5>FAQ</h5>
          <p>Answers to All Your Questions</p>
          </div>
          </div>
        </Link>
      </li>
      </ul>

      <ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/Blog' || activeLink === '/Blog' ? 'active' : ''}`}>
        <Link to="/Blog" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon"xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M760-440h-80q-17 0-28.5-11.5T640-480q0-17 11.5-28.5T680-520h80q17 0 28.5 11.5T800-480q0 17-11.5 28.5T760-440ZM584-288q10-14 26-16t30 8l64 48q14 10 16 26t-8 30q-10 14-26 16t-30-8l-64-48q-14-10-16-26t8-30Zm120-424-64 48q-14 10-30 8t-26-16q-10-14-8-30t16-26l64-48q14-10 30-8t26 16q10 14 8 30t-16 26ZM280-360H160q-17 0-28.5-11.5T120-400v-160q0-17 11.5-28.5T160-600h120l132-132q19-19 43.5-8.5T480-703v446q0 27-24.5 37.5T412-228L280-360Zm120-246-86 86H200v80h114l86 86v-252ZM300-480Z" fill="url(#gradient)"/></svg>        <div className='bot-details'>
          <h5>Blog</h5>
          <p>Keep up to date with all things Tio</p>
          </div>
          </div>
        </Link>
      </li>
      </ul>

      <ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/OurTeam' || activeLink === '/OurTeam' ? 'active' : ''}`}>
        <Link to="/OurTeam" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M711-480Zm49 80q-17 0-28.5-11.5T720-440q0-17 11.5-28.5T760-480h77q-4-7-9-12t-12-9q-26-15-59.5-22t-76.5-7h-21q-17 0-28-11t-11-28q0-17 9.5-27.5T656-609q5-1 12-1h12q53 0 99 11t86 32q26 14 40.5 41.5T920-463v23q0 17-11.5 28.5T880-400H760Zm-80-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0-40ZM249-480ZM80-400q-17 0-28.5-11.5T40-440v-23q0-35 14.5-62.5T95-567q40-21 86-32t99-11h12q7 0 12 1 17 2 26.5 12.5T340-569q0 17-11 28t-28 11h-21q-43 0-76.5 7T144-501q-7 4-12 9t-9 12h77q17 0 28.5 11.5T240-440q0 17-11.5 28.5T200-400H80Zm200-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T320-760q0-17-11.5-28.5T280-800q-17 0-28.5 11.5T240-760q0 17 11.5 28.5T280-720Zm0-40Zm200 480q-33 0-56.5-23.5T400-360v-120q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480v120q0 33-23.5 56.5T480-280Zm0 200q-13 0-21.5-8.5T450-110v-52q-65-10-111.5-56T283-325q-2-14 7-24.5t23-10.5q11 0 19 6.5t10 17.5q11 52 49 84t89 32q51 0 89-32t49-84q2-11 10-17.5t19-6.5q14 0 23 10.5t7 24.5q-9 61-55.5 107T510-162v52q0 13-8.5 21.5T480-80Z" fill="url(#gradient)"/></svg>        <div className='bot-details'>
          <h5>About Us</h5>
          <p>Discover Tio beyond the Penguin</p>
          </div>
          </div>
        </Link>
      </li>
      </ul>
</div>

      <div className='row'>
      <ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/ExpertResources' || activeLink === '/ExpertResources' ? 'active' : ''}`}>
        <Link to="/ExpertResources" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon"xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M480-80q-33 0-56.5-23.5T400-160h160q0 33-23.5 56.5T480-80ZM360-200q-17 0-28.5-11.5T320-240q0-17 11.5-28.5T360-280h240q17 0 28.5 11.5T640-240q0 17-11.5 28.5T600-200H360Zm-30-120q-69-41-109.5-110T180-580q0-125 87.5-212.5T480-880q125 0 212.5 87.5T780-580q0 81-40.5 150T630-320H330Zm24-80h252q45-32 69.5-79T700-580q0-92-64-156t-156-64q-92 0-156 64t-64 156q0 54 24.5 101t69.5 79Zm126 0Z" fill="url(#gradient)"/></svg>        <div className='bot-details'>
          <h5>Expert Resources</h5>
          <p>Our resources on improving your expert journey</p>
          </div>
          </div>
        </Link>
      </li>
</ul>

<ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/ContactUs' || activeLink === '/ContactUs' ? 'active' : ''}`}>
        <Link to="/ContactUs" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M480-400q33 0 56.5-23.5T560-480q0-33-23.5-56.5T480-560q-33 0-56.5 23.5T400-480q0 33 23.5 56.5T480-400ZM320-240h320v-23q0-24-13-44t-36-30q-26-11-53.5-17t-57.5-6q-30 0-57.5 6T369-337q-23 10-36 30t-13 44v23ZM720-80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h287q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v447q0 33-23.5 56.5T720-80Zm0-80v-446L526-800H240v640h480Zm-480 0v-640 640Z"fill="url(#gradient)"/> </svg>        <div className='bot-details'>
          <h5>Contact</h5>
          <p>We're there when you need us</p>
          </div>
          </div>
        </Link>
      </li>
</ul>

<ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/HelpCenter' || activeLink === '/HelpCenter' ? 'active' : ''}`}>
        <Link to="/HelpCenter" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon" xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M680-160q8 0 14-6t6-14v-120q0-8-6-14t-14-6q-8 0-14 6t-6 14v120q0 8 6 14t14 6Zm0-200q8 0 14-6t6-14q0-8-6-14t-14-6q-8 0-14 6t-6 14q0 8 6 14t14 6ZM200-800v640-640 200-200Zm120 400h100q17 0 28.5-11.5T460-440q0-17-11.5-28.5T420-480H320q-17 0-28.5 11.5T280-440q0 17 11.5 28.5T320-400Zm0 160h40q17 0 28.5-11.5T400-280q0-17-11.5-28.5T360-320h-40q-17 0-28.5 11.5T280-280q0 17 11.5 28.5T320-240ZM200-80q-33 0-56.5-23.5T120-160v-640q0-33 23.5-56.5T200-880h287q16 0 30.5 6t25.5 17l194 194q11 11 17 25.5t6 30.5v13q0 17-11.5 28.5T720-554q-17 0-28.5-11.5T680-594v-6H520q-17 0-28.5-11.5T480-640v-160H200v640h221q17 0 28.5 11.5T461-120q0 17-11.5 28.5T421-80H200Zm480-400q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480Z"fill="url(#gradient)"/></svg>        <div className='bot-details'>
          <h5>Help Center</h5>
          <p>Collected by Tio himself</p>
          </div>
          </div>
        </Link>
      </li>
</ul>

<ul className="col-3">
      <li className={`dropdown-item-menu no-underline gradient-underline ${activeLink === '/OurMission' || activeLink === '/OurMission' ? 'active' : ''}`}>
        <Link to="/OurMission" className="nav-link-menu n" onClick={handleDropdownItemClick}>
        <div className='d-flex'>
        <svg className="svg-list-icon"xmlns="http://www.w3.org/2000/svg"  viewBox="0 -960 960 960" >  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs><path d="M440-200v-124q-49-11-87.5-41.5T296-442q-75-9-125.5-65.5T120-640v-40q0-33 23.5-56.5T200-760h80q0-33 23.5-56.5T360-840h240q33 0 56.5 23.5T680-760h80q33 0 56.5 23.5T840-680v40q0 76-50.5 132.5T664-442q-18 46-56.5 76.5T520-324v124h120q17 0 28.5 11.5T680-160q0 17-11.5 28.5T640-120H320q-17 0-28.5-11.5T280-160q0-17 11.5-28.5T320-200h120ZM280-528v-152h-80v40q0 38 22 68.5t58 43.5Zm200 128q50 0 85-35t35-85v-240H360v240q0 50 35 85t85 35Zm200-128q36-13 58-43.5t22-68.5v-40h-80v152Zm-200-52Z"fill="url(#gradient)"/></svg>        <div className='bot-details'>
          <h5>Our Mission</h5>
          <p>Discover waht makes us better than the rest</p>
          </div>
          </div>
        </Link>
      </li>
</ul>

      </div>
    </div>
  );
};

export default ServicesDropdown;