import React, { useContext, useState, useEffect, useRef } from 'react';
import { auth, firestore, sendVerificationEmail, db, onAuthStateChanged, query, limit, orderBy } from '../firebase';
import { Link, useNavigate } from 'react-router-dom';
import MobileNavbar from '../boxes/MobileNavbar';
import DropdownMenu from '../boxes/DropdownMenu';
import { Fade as Hamburger } from 'hamburger-react';
import { doc, getDoc, onSnapshot, getDocs, collection } from 'firebase/firestore';
import SearchBar from './SearchBar'; 
import LoadingCircle from './LoadingCircle';
import MobileSearch from '../boxes/MobileSearch'; 
import { updateProfile } from 'firebase/auth';
import { PopupContext } from '../boxes/PopupContext';
import ServicesDropdown from '../boxes/ServicesDropdown';
import IosPopup from './IosPopup';
import NavbarSkeleton from './NavbarSkeleton';

const DESKTOP_BREAKPOINT = 1024;

function Navbar() {
  const { handleLoginButtonClick } = useContext(PopupContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [, setUserEmail] = useState('');
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const [isSpinnerShowing, setIsSpinnerShowing] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isApplicationApproved, setIsApplicationApproved] = useState(false);
  const [isDropdownOpenMenu, setIsDropdownOpenMenu] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [points, setPoints] = useState(0); 
  const [, setIsPointsFetched] = useState(false);
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false)
  const [showVerificationPopup, setShowVerificationPopup] = useState(false);
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);
  const [, setUserName] = useState('');
  const [activeLink, setActiveLink] = useState('');
  const [, setUser] = useState(null);
  const navbarRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  
  const handleShowPopup = () => {
    setShowPopup(true);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
      setIsCheckingAuth(false);
  
      if (user) {
        const { displayName, emailVerified, email } = user;
        const firstName = displayName ? displayName.split(' ')[0] : '';
        setUserName(firstName);
        setIsLoggedIn(true);
        setIsEmailVerified(emailVerified);
        setUserEmail(email);
        setUser(user);
      } else {
        setUserName('');
        setIsLoggedIn(false);
        setIsEmailVerified(false);
        setUserEmail('');
        setUser(null);
      }
    });
  
    return unsubscribe;
  }, [isApplicationApproved]);

  useEffect(() => {
    setIsSpinnerShowing(isCheckingAuth);
  }, [isCheckingAuth]);
  
  const buttonText = isSpinnerShowing ? (
    <LoadingCircle />
  ) : authUser ? (
    (authUser.displayName ? authUser.displayName.split(' ')[0] : '') || 'Login'
  ) : (
    'Login'
  );


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthUser(user);
  
      if (user) {
        const { displayName } = user;
        const firstName = displayName ? displayName.split(' ')[0] : '';
        setUserName(firstName);
      } else {
        setUserName('');
      }
    });
  
    return unsubscribe;
  }, [isApplicationApproved]);
  

  
  useEffect(() => {
    setIsSpinnerShowing(isCheckingAuth);
  }, [isCheckingAuth]);
  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        const { emailVerified, email } = authUser;
        setUser(authUser);
        setIsLoggedIn(true);
        setIsEmailVerified(emailVerified);
        setUserEmail(email);
        setPoints(0); // Reset points when the user logs in
        setUserName(authUser.displayName || ''); // Use authUser.displayName instead of userDisplayName
      } else {
        setUser(null);
        setIsLoggedIn(false);
        setIsEmailVerified(false);
        setDisplayName('');
        setUserEmail('');
        setPoints(0); // Reset points when the user logs out
        setUserName('');
      }
      setIsCheckingAuth(false);
    });
  
    return () => {
      unsubscribe();
    };
  }, []);
  
  
  const handleResendVerificationEmail = () => {
    sendVerificationEmail()
      .then(() => {
        setEmailSentSuccessfully(true);
      })
      .catch((error) => {
        console.error('Error sending verification email:', error);
      });
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      console.log('Signed out successfully');
      setIsApplicationApproved(false); // Reset the expert approval status
      setHasUnreadMessages(false); 
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  
  
  useEffect(() => {
    function handleDocumentClick(event) {
      if (
        isDropdownOpen &&
        isDropdownOpenMenu &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        event.target.id !== 'servicesDropdown'
      ) {
        setIsDropdownOpenMenu(false);
      }
    }
  
    document.addEventListener('click', handleDocumentClick);
  
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isDropdownOpenMenu]);

  const toggleNavbar = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

 

  const isDesktop = window.innerWidth >= DESKTOP_BREAKPOINT;
  const isMobile = window.innerWidth < DESKTOP_BREAKPOINT;


  useEffect(() => {
    const fetchExpertStatus = async () => {
      if (isLoggedIn && auth.currentUser) { // Add null check here
        try {
          const user = auth.currentUser;
          const uid = user.uid;
          const docRef = doc(firestore, 'expertApplications', uid); // Use the UID
          const docSnap = await getDoc(docRef);
  
          if (docSnap.exists()) {
            const data = docSnap.data();
            setIsApplicationApproved(data.approved);
          }
        } catch (error) {
        }
      }
    };
  
    fetchExpertStatus();
  }, [isLoggedIn]);
  

  useEffect(() => {
    const fetchUserPoints = async () => {
      if (isLoggedIn && auth.currentUser) {
        try {
          const user = auth.currentUser;
          const uid = user.uid;
          const collectionName = isApplicationApproved ? 'expertApplications' : 'userProfiles';
          const docRef = doc(firestore, collectionName, uid);
    
          const unsubscribe = onSnapshot(docRef, async (docSnap) => { // Make this callback async
            if (docSnap.exists()) {
              const data = docSnap.data();
              const userPoints = data.points || 0;
              setPoints(userPoints);
              setDisplayName(data.firstName || ''); // Use the displayName from Firestore
    
              if (isApplicationApproved) { // Check if the application is approved
                // Update the authenticated user's display name in Firebase Authentication
                await updateProfile(user, {
                  displayName: `${data.firstName} ${data.lastName}`,
                });
              }
    
              setIsPointsFetched(true); // Set this flag when points are fetched
            } else {
              // Handle case when document doesn't exist
            }
          });
    
          return () => unsubscribe(); // Unsubscribe from snapshot listener when component unmounts
        } catch (error) {
          console.error('Error fetching user points:', error);
        }
      }
    };
    
  
    fetchUserPoints();
  }, [isLoggedIn, isApplicationApproved]);
  
  

  useEffect(() => {
    const unsubscribeChats = onSnapshot(collection(db, 'chats'), (chatsSnapshot) => {
      chatsSnapshot.forEach(async (chatDoc) => {
        const chatID = chatDoc.id;
        const messagesCollectionRef = collection(db, `chats/${chatID}/messages`);
  
        // Query the messages collection to get the last added message
        const q = query(messagesCollectionRef, orderBy('timestamp', 'desc'), limit(1));
  
        const unsubscribeMessages = onSnapshot(q, (messagesSnapshot) => {
          let hasUnreadMessages = false;
  
          // Check if there is a last added message
          if (!messagesSnapshot.empty) {
            const lastMessage = messagesSnapshot.docs[0].data();
            console.log('Last message:', lastMessage); // Log the last message
  
            // Check if the user is authenticated and if the last message is for the current user and unread
            if (
              auth.currentUser &&
              lastMessage.receiver === auth.currentUser.uid &&
              !lastMessage.isRead
            ) {
              hasUnreadMessages = true;
            }
          }
  
          // Log the state change
          console.log('hasUnreadMessages state:', hasUnreadMessages);
  
          // Update state with the latest unread messages status
          setHasUnreadMessages(hasUnreadMessages);
        });
  
        return () => unsubscribeMessages(); // Unsubscribe from message snapshot
      });
    });
  
    return () => {
      // Log when unsubscribing from chat snapshot
      console.log('Unsubscribing from chat snapshot');
      unsubscribeChats();
    };
  }, []);
  
  
  
  useEffect(() => {
    if (auth.currentUser?.uid) { // Use auth.currentUser?.uid instead of currentUserUID
      const roomsCollectionRef = collection(db, 'rooms');
      const callsCollectionRef = collection(db, 'calls');
  
      const unsubscribeRooms = onSnapshot(roomsCollectionRef, (roomsSnapshot) => {
        let hasUnreadRooms = false;
  
        roomsSnapshot.forEach((roomDoc) => {
          const roomId = roomDoc.id;
  
          // Check if the room ID starts with the current user's ID
          if (roomId.startsWith(auth.currentUser?.uid)) { // Use auth.currentUser?.uid here as well
            hasUnreadRooms = true;
          }
        });
  
        setHasUnreadMessages(hasUnreadRooms);
      });
  
      const unsubscribeCalls = onSnapshot(callsCollectionRef, (callsSnapshot) => {
        let hasUnreadCalls = false;
  
        callsSnapshot.forEach((callDoc) => {
          const callId = callDoc.id;
  
          // Check if the call ID starts with the current user's ID
          if (callId.startsWith(auth.currentUser?.uid)) { // Use auth.currentUser?.uid here as well
            hasUnreadCalls = true;
          }
        });
  
        setHasUnreadMessages(hasUnreadCalls); // You can use the same state variable (setHasUnreadMessages) if you want to check for unread messages in both rooms and calls
      });
  
      return () => {
        unsubscribeRooms();
        unsubscribeCalls();
      };
    }
  }, [auth.currentUser?.uid]);
  
  
  
  
  
  


  const handleCloseVerificationPopup = () => {
    setShowVerificationPopup(false);
  };




  
  const toggleSearchBar = () => {
    setIsSearchBarOpen(!isSearchBarOpen);
  };

  // Add this function to close the search bar explicitly
  const closeSearchBar = () => {
    setIsSearchBarOpen(false);
  };

  const handleLogoutClick = async () => {
    try {
      await handleLogout(); // Wait for the logout operation to complete
      setShowVerificationPopup(false); // Close the popup
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
  

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // Function to handle click on link
  const handleLinkClick = (path) => {
    setActiveLink(path);
    setIsDropdownOpen(false);
    setIsDropdownOpenMenu(false);
  };

  const handleChatLinkClick = (event, path) => {
    event.preventDefault(); // Prevent the default link behavior
    if (!isLoggedIn) {
      handleLoginButtonClick(); // Show the login popup
    } else {
      setActiveLink(path); // Update the active link
      navigate(path); // Navigate to the specified route
    }
  };

  const handleServiceClick = () => {
    setIsDropdownOpen(false); // Close the user dropdown
    setIsDropdownOpenMenu(!isDropdownOpenMenu);
  };

  const onLoginButtonClick = () => {
    if (!isSpinnerShowing) {
      if (isLoggedIn) {
        if (isEmailVerified) {
          setIsDropdownOpenMenu(false); // Close the services dropdown
          setIsDropdownOpen(!isDropdownOpen);
        } else {
          setShowVerificationPopup(true);
        }
      } else {
        handleLoginButtonClick();
      }
    }
  };

  const handleDropdownItemClick = () => {
    setIsNavCollapsed(true);
    setIsDropdownOpenMenu(false); // Close the services dropdown
    setIsDropdownOpen(false); // Close the user dropdown
  };

  const handleMouseEnterLogin = () => {
    if (!isLoggedIn || !isEmailVerified) {
      return;
    }
  
    setIsDropdownOpenMenu(false); // Close the services dropdown
    setIsDropdownOpen(true);
  };
  const handleMouseEnterServices = () => {
    setIsDropdownOpen(false); // Close the user dropdown
    setIsDropdownOpenMenu(true);
  };

  const handleClick = (e) => {
    e.preventDefault();
    navigate('/');
  };

  


  if (isCheckingAuth) {
    return <NavbarSkeleton />;
  }

  return (
    <nav
      ref={navbarRef}
      className={`navbar d-flex navbar-expand-lg navbar-dark menu shadow fixed-top${isNavCollapsed ? '' : ' open'}`}
    >
      <ServicesDropdown
        activeLink={activeLink}
        handleDropdownItemClick={handleDropdownItemClick}
        isDropdownOpenMenu={isDropdownOpenMenu}
        navbarRef={navbarRef}
      />
      <div className="container custom-navbar-container position-relative">
      <a 
        className="navbar-brand" 
        href="/" 
        onClick={handleClick}
        aria-label="TioAssist Home"
      >
        <img
          src="/images/logo/LogoTio.webp"
          alt="TioAssist logo"
          width="990"
          height="275"
        />
      </a>

        {isDesktop && <SearchBar />}

        {isMobile && !isCheckingAuth && !isLoggedIn && (
          <div className="mobile-toggler-text">
            <span className="join-text" onClick={onLoginButtonClick}>
              Join
            </span>
          </div>  
        )}

        {isMobile && (
          <div className="mobile-icons">
            <div>
            <MobileSearch 
          isSearchBarOpen={isSearchBarOpen} 
          onSearchBarToggle={toggleSearchBar}
          onClose={closeSearchBar}
        />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                color="#ffffff"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                height="25"
                width="25"
                onClick={toggleSearchBar}
                aria-label="Search"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
            <div className="hamburger-wrapper" onClick={toggleNavbar}>
              <div className={`hamburger-container ${hasUnreadMessages ? 'unread-border-hamburger' : ''}`}>
                <div className="hamburger-inner">
                  <div className="hamburger-react" aria-expanded={!isNavCollapsed} role="button" tabIndex={0}>
                    <span className="visually-hidden">Toggle Menu</span>
                    <Hamburger aria-label="Hamburger" size={28} color="white" toggled={!isNavCollapsed} toggle={toggleNavbar} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isMobile && (
          <MobileNavbar
            isOpen={!isNavCollapsed}
            toggleNavbar={toggleNavbar}
            onSubmitButtonClick={onLoginButtonClick}
            isLoggedIn={isLoggedIn}
            displayName={displayName}
            handleLogout={handleLogout}
            isApplicationApproved={isApplicationApproved}
            points={points}
            hasUnreadMessages={hasUnreadMessages}
            showPopup={showPopup}
            setShowPopup={handleShowPopup}
            isLoading={isCheckingAuth}
          />
        )}
        {showPopup && <IosPopup showPopup={showPopup} setShowPopup={setShowPopup} />}
        
        <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul className="navbar-nav">
  <li className={`nav-item ${activeLink === '/' ? 'active' : ''}`}>
    <Link to="/" className="nav-link" onClick={() => handleLinkClick('/')}>
      Home
    </Link>
  </li>
  <li className={`nav-item ${activeLink === '/chatBot' ? 'active' : ''}`}>
  <Link
    to="/chatBot"
    className="nav-link"
    onClick={(event) => handleChatLinkClick(event, '/chatBot')}
  >
    Chat
  </Link>
</li>
  <li className={`nav-item ${activeLink === '/Shop' ? 'active' : ''}`}>
    <Link to="/Shop" className="nav-link" onClick={() => handleLinkClick('/Shop')}>
      Shop
    </Link>
  </li>
  <li
    className={`nav-item file-container  dropdown ${
      activeLink.startsWith('/services') || activeLink === '/Pricing' || activeLink === '/FAQ' ? 'active' : ''
    } ${isDropdownOpenMenu ? 'show' : ''}`}
    onMouseEnter={handleMouseEnterServices}
  >
<a
  className="nav-link padding-link-arrow"
  href="#"
  id="servicesDropdownTrigger"
  role="button"
  data-bs-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded={isDropdownOpenMenu ? 'true' : 'false'}
  onClick={handleServiceClick}
>
  Discover

</a>
<span
  className={`dropdown-arrow ${isDropdownOpenMenu ? 'rotate-icon-nav fill-white' : 'fill-white'}`}
  aria-hidden="true"
>
  <svg
    fill="white"
    width="16"
    height="16"
    viewBox="0 0 8 16"
    xmlns="http://www.w3.org/2000/svg"
    style={{ fill: isDropdownOpenMenu ? 'white' : 'rgba(255, 255, 255, 0.55)' }}
  >
    <path d="M0.772126 1.19065L0.153407 1.80934C0.00696973 1.95578 0.00696973 2.19322 0.153407 2.33969L5.80025 8L0.153407 13.6603C0.00696973 13.8067 0.00696973 14.0442 0.153407 14.1907L0.772126 14.8094C0.918563 14.9558 1.156 14.9558 1.30247 14.8094L7.84666 8.26519C7.99309 8.11875 7.99309 7.88131 7.84666 7.73484L1.30247 1.19065C1.156 1.04419 0.918563 1.04419 0.772126 1.19065Z"></path>
  </svg>
</span>
</li>
  <li className={`nav-item ${activeLink === '/ExpertVerificationPage' || activeLink === '/BecomeAnExpert' ? 'active' : ''}`}>
    {isLoggedIn ? (
      <Link to="/ExpertVerification" className="nav-link">
        Become an Expert
      </Link>
    ) : (
      <Link to="/BecomeAnExpert" className="nav-link">
        Become an Expert
      </Link>
    )}
  </li>
</ul>


          {isLoggedIn && (
        
        <div className='points-mobile '>
  <span className="points">{points}</span>
  <br />
  <span className="tio">Tio-Points</span>
</div>
)}
        {isDesktop && (
          <div className="dropdown" ref={dropdownRef}>
            <div className={`button-container-login ${hasUnreadMessages ? 'unread-border-login' : ''}`}>
              <button
                className="btn-rounded span login-button custom-dropdown-toggle"
                type="button"
                onMouseEnter={handleMouseEnterLogin}
                id="loginButtonNavDropdown"
                onClick={onLoginButtonClick}
              >
                {buttonText}
                {isApplicationApproved && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="svgIcon"
                    color="#212529"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    height="25"
                    width="25"
                    style={{ marginLeft: '10px' }}
                  >
                    <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                    <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                    <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        )}  


{isLoggedIn && (
          <DropdownMenu
            isOpen={isDropdownOpen}
            handleDropdownItemClick={handleDropdownItemClick}
            handleLogout={handleLogout}
            isApplicationApproved={isApplicationApproved} 
            closeDropdown={() => setIsDropdownOpen(false)} 
            hasUnreadMessages={hasUnreadMessages} 
          />
        )}
        </div>
      
        {showVerificationPopup && (
  <div className="blurred-background">
    <div className="pending-application-popup">
      {emailSentSuccessfully ? (
        <p>Email sent successfully, please check your inbox.</p>
      ) : (
        <p>
        Please verify your email. If you have not received an email,{" "}
        <a
          href="#"
          className="resend-email-link"
          onClick={handleResendVerificationEmail}
        >
          click here
        </a>{" "}
        or{" "}
           
        to Logout  {" "} <a
href="#"
               className="resend-email-link"
       onClick={handleLogoutClick} 
        >
          click here.
          </a>
      </p>
      
      
      )}
      <div>
        <a
          href="#"
          className="resend-email-link"
          onClick={handleCloseVerificationPopup}
        >
          close
        </a>
      </div>
    </div>
  </div>
)}




      </div>
    </nav>
  );
}

export default Navbar;