import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { PopupContext } from '../boxes/PopupContext';

const categories = [
  { name: 'Rocket League', icon:    <svg width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
  <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
        </linearGradient>


  </defs>
  <path d="M182-200q-51 0-79-35.5T82-322l42-300q9-60 53.5-99T282-760h396q60 0 104.5 39t53.5 99l42 300q7 51-21 86.5T778-200q-21 0-39-7.5T706-230l-90-90H344l-90 90q-15 15-33 22.5t-39 7.5Zm16-86 114-114h336l114 114q2 2 16 6 11 0 17.5-6.5T800-304l-44-308q-4-29-26-48.5T678-680H282q-30 0-52 19.5T204-612l-44 308q-2 11 4.5 17.5T182-280q2 0 16-6Zm482-154q17 0 28.5-11.5T720-480q0-17-11.5-28.5T680-520q-17 0-28.5 11.5T640-480q0 17 11.5 28.5T680-440Zm-80-120q17 0 28.5-11.5T640-600q0-17-11.5-28.5T600-640q-17 0-28.5 11.5T560-600q0 17 11.5 28.5T600-560Zm-120 80Zm-170-30v40q0 13 8.5 21.5T340-440q13 0 21.5-8.5T370-470v-40h40q13 0 21.5-8.5T440-540q0-13-8.5-21.5T410-570h-40v-40q0-13-8.5-21.5T340-640q-13 0-21.5 8.5T310-610v40h-40q-13 0-21.5 8.5T240-540q0 13 8.5 21.5T270-510h40Z"
 fill="url(#gradient)"/>
</svg>,
questions: [
  'What is the most effective way to improve my Rocket League gameplay and climb the ranks?',
  'How can I master aerial shots in Rocket League?',
  'What are some advanced rotation strategies for high-level Rocket League play?'
]},

  { name: 'Dog Training', icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
         <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
         <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
        </linearGradient>
  </defs>
  <path d="M180-475q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180-160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm240 0q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29Zm180 160q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM266-75q-45 0-75.5-34.5T160-191q0-52 35.5-91t70.5-77q29-31 50-67.5t50-68.5q22-26 51-43t63-17q34 0 63 16t51 42q28 32 49.5 69t50.5 69q35 38 70.5 77t35.5 91q0 47-30.5 81.5T694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z" fill="url(#gradient)"/>
</svg>,
  questions: [
    'What is my dog thinking?',
    'How can I effectively train my dog to stop barking at strangers?',
    'What are some advanced obedience training techniques for dogs?'
  ] },

  { name: 'Your next vacation', icon:  <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M120-120v-80h720v80H120Zm74-200L80-514l62-12 70 62 192-52-162-274 78-24 274 246 200-54q32-9 58 12t26 56q0 22-13.5 39T830-492L194-320Z"     fill="url(#gradient)"/>
</svg>,
  questions: [
    'What are some off-the-beaten-path destinations for a unique vacation experience?',
    'How can I plan a budget-friendly yet exciting international trip?',
    'What are the best strategies for maximizing frequent flyer miles and hotel points for a luxury vacation?'
  ]},

  { name: 'Beatboxing Lessons',  icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M480-680q17 0 28.5 11.5T520-640v480q0 17-11.5 28.5T480-120q-17 0-28.5-11.5T440-160v-480q0-17 11.5-28.5T480-680ZM320-560q17 0 28.5 11.5T360-520v240q0 17-11.5 28.5T320-240q-17 0-28.5-11.5T280-280v-240q0-17 11.5-28.5T320-560Zm320 0q17 0 28.5 11.5T680-520v240q0 17-11.5 28.5T640-240q-17 0-28.5-11.5T600-280v-240q0-17 11.5-28.5T640-560ZM480-760q-45 0-84.5 22.5T333-676q-32 54-87.5 85T127-560h-7q-17 0-28.5-11.5T80-600q0-17 11.5-28.5T120-640h7q42 0 78.5-21t58.5-57q34-57 91.5-89.5T480-840q67 0 124.5 32.5T696-718q22 36 58.5 57t78.5 21h7q17 0 28.5 11.5T880-600q0 17-11.5 28.5T840-560h-6q-63 0-118.5-31T628-676q-23-39-62.5-61.5T480-760ZM160-360q-17 0-28.5-11.5T120-400q0-17 11.5-28.5T160-440q17 0 28.5 11.5T200-400q0 17-11.5 28.5T160-360Zm640 0q-17 0-28.5-11.5T760-400q0-17 11.5-28.5T800-440q17 0 28.5 11.5T840-400q0 17-11.5 28.5T800-360Z" 
   fill="url(#gradient)"/>
</svg>,
 questions: [
  'How can I improve my beatboxing skills and develop my own unique sound?',
  'What are the fundamental techniques every beginner beatboxer should master?',
  'How can I incorporate advanced rhythms and vocal effects into my beatboxing routines?'
] },



  { name: 'Painting', icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 32.5-156t88-127Q256-817 330-848.5T488-880q80 0 151 27.5t124.5 76q53.5 48.5 85 115T880-518q0 115-70 176.5T640-280h-74q-9 0-12.5 5t-3.5 11q0 12 15 34.5t15 51.5q0 50-27.5 74T480-80Zm0-400Zm-220 40q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120-160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm200 0q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm120 160q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17ZM480-160q9 0 14.5-5t5.5-13q0-14-15-33t-15-57q0-42 29-67t71-25h70q66 0 113-38.5T800-518q0-121-92.5-201.5T488-800q-136 0-232 93t-96 227q0 133 93.5 226.5T480-160Z"
     fill="url(#gradient)"/>
</svg>,
questions: [
  'How do I become the next Picasso?',
  'What are some innovative techniques to create texture in acrylic paintings?',
  'How can I develop my own unique art style while drawing inspiration from famous artists?'
] },

  { name: 'Writing & Translation', icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M338-241q16 0 23-10.5t9-24.5q2-10 3.5-20t3.5-22q2-11 4.5-24t5.5-30q23-5 45-8.5t43-5.5q23-3 45.5-4.5T564-394q5 24 10.5 43t11.5 36q8 23 17.5 38t23.5 26q14 11 30.5 12t28.5-9q9-7 9-21t-8-35q-5-11-8.5-22.5T670-350q-5-14-9-25.5t-7-22.5q13-1 23.5-4.5T695-412q7-6 10.5-14.5T709-445q0-11-4.5-18.5T691-476q-9-5-22.5-6.5t-30.5.5q-2-18-4-35.5t-5-35.5q-3-17-5.5-35t-7.5-35q-6-26-17-44.5T574-698q-13-11-28.5-16.5T511-720q-22 0-42 9t-40 27q-11 11-22 23.5T386-631q-8-6-14.5-8t-14.5-2q-11 0-18.5 6t-7.5 20q0 18-2 36t-6 36q-5 26-11 51.5T301-440q-11 2-19.5 5.5T267-427q-8 5-11.5 12.5T252-399q0 7 2 13t7 11q5 5 12 7.5t16 3.5q-1 12-1.5 22.5T287-321q0 21 3 36t9 25q6 10 15.5 14.5T338-241Zm71-223q6-23 14-44.5t18-44.5q16-37 34-59t32-22q11 0 19 17t13 51q3 20 5 43t4 43q-17 1-35 2.5t-35 3.5q-17 2-34.5 4.5T409-464ZM160-80q-33 0-56.5-23.5T80-160v-640q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v640q0 33-23.5 56.5T800-80H160Zm0-80h640v-640H160v640Zm0 0v-640 640Z"     fill="url(#gradient)"/>
</svg>,
questions: [
  "How can I translate a piece of text while still preserving its original tone and style?",
  "What are some effective techniques for overcoming writer's block?", // use double quotes
  "How can I improve my skills in creative writing across different genres?"
]
},

  { name: 'Finding a new role', icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="M160-80q-33 0-56.5-23.5T80-160v-440q0-33 23.5-56.5T160-680h200v-120q0-33 23.5-56.5T440-880h80q33 0 56.5 23.5T600-800v120h200q33 0 56.5 23.5T880-600v440q0 33-23.5 56.5T800-80H160Zm0-80h640v-440H600q0 33-23.5 56.5T520-520h-80q-33 0-56.5-23.5T360-600H160v440Zm80-80h240v-18q0-17-9.5-31.5T444-312q-20-9-40.5-13.5T360-330q-23 0-43.5 4.5T276-312q-17 8-26.5 22.5T240-258v18Zm320-60h160v-60H560v60Zm-200-60q25 0 42.5-17.5T420-420q0-25-17.5-42.5T360-480q-25 0-42.5 17.5T300-420q0 25 17.5 42.5T360-360Zm200-60h160v-60H560v60ZM440-600h80v-200h-80v200Zm40 220Z"       fill="url(#gradient)"/>
</svg>,
 questions: [
  'What are some unconventional job search strategies that can help me land my dream role?',
  'How can I effectively transition my skills from one industry to another?',
  'What are the best ways to network and build professional relationships in the digital age?'
] },

  { name: 'Gaming Strategies', icon:    <svg  width="30" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style={{stopColor: '#9926f0', stopOpacity: 1}} />
      <stop offset="100%" style={{stopColor: '#d122e3', stopOpacity: 1}} />
    </linearGradient>
  </defs>
  <path d="m200-531-100-57q-9-5-14.5-14.5T80-623v-114q0-11 5.5-20.5T100-772l100-57q9-5 20-5t20 5l100 57q9 5 14.5 14.5T360-737v114q0 11-5.5 20.5T340-588l-100 57q-9 5-20 5t-20-5Zm20-81 60-34v-68l-60-34-60 34v68l60 34Zm440 123v-93l100 59q19 11 29.5 29.5T800-454v188q0 21-10.5 39.5T760-197l-160 93q-19 11-40 11t-40-11l-160-93q-19-11-29.5-29.5T320-266v-188q0-21 10.5-39.5T360-523l100-59v93l-60 35v188l160 93 160-93v-188l-60-35Zm-60-151v200q0 17-11.5 28.5T560-400q-17 0-28.5-11.5T520-440v-400q0-17 11.5-28.5T560-880h245q24 0 36 21t-2 41l-24 36q-7 10-7 22t7 22l24 36q14 20 2 41t-36 21H600Zm-40 309ZM220-680Z"       fill="url(#gradient)"/>
</svg>,
 questions: [
  'What are some advanced techniques for improving my chess game?',
  'How can I optimize my gameplay and decision-making in real-time strategy games?',
  'What are effective ways to build and manage a successful eSports team?'
] },

  // Add more unique categories here
];


const CategoryIcons = () => {
  const [, setCategorySentence] = useState('');
  const { handleLoginButtonClick } = useContext(PopupContext);
  const navigate = useNavigate();


  useEffect(() => {
    setCategorySentence('');
  }, []);

  const handleChat = (category) => {
    const user = auth.currentUser;
    if (user) {
      const randomQuestion = category.questions[Math.floor(Math.random() * category.questions.length)];
      navigate('/chatbot', { state: { categorySentence: randomQuestion } });
    } else {
      handleLoginButtonClick();
    }
  };
  
  

  useEffect(() => {
    setCategorySentence('');
  }, []);



  return (
    <div className='mt-2'>
      <div className="tio-category-icons">
        {categories.map((category, index) => (
         <div key={index} className="tio-category-icon" onClick={() => handleChat(category)}>
            <div className="tio-category-icon__icon">{category.icon}</div>
            <div className="tio-category-icon__label">{category.name}</div>
          </div>
        ))}
      </div>
      <div className='cat-chat tio-chat mb-0'>
        <span className="" >
          ...and everything else
        </span>
      </div>
    </div>
  );
};

export default CategoryIcons;