import React, { useState, useEffect } from 'react';

const InstallPromptButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallPromptVisible, setIsInstallPromptVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallPromptVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          setIsInstallPromptVisible(false); // Hide the button after successful installation
        } else {
          console.log('User dismissed the install prompt');
          setIsInstallPromptVisible(false); // Hide the button after dismissing the prompt
        }
        setDeferredPrompt(null);
      });
    }
  };

  return isInstallPromptVisible ? (
    <button onClick={handleInstallClick} className="btn btn-secondary cartoon-shadow-secondary">
      Install Tio
    </button>
  ) : null;
};

export default InstallPromptButton;