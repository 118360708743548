import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import InstallPromptButton from '../components/InstallPromptButton';
import LoadingCircleSmall from '../components/LoadingCircleSmall';
import useBodyScrollLock from '../components/useBodyScrollLock';

function MobileNavbar({ showPopup, setShowPopup, isOpen, toggleNavbar, onSubmitButtonClick, isLoggedIn, displayName, handleLogout, isApplicationApproved, points, hasUnreadMessages, isLoading }) {
const navbarRef = useRef(null);
const [isDropdownOpen, setDropdownOpen] = useState(false);
const [isOverlayVisible, setOverlayVisible] = useState(false);
const location = useLocation();
const [activeLink, setActiveLink] = useState('');
const { pathname } = useLocation();
const [bodyScrollLocked, setBodyScrollLocked] = useBodyScrollLock();

useEffect(() => {
    setOverlayVisible(isOpen);
    if (isOpen) {
      document.body.classList.add('navbar-open');
    } else {
      document.body.classList.remove('navbar-open');
    }
  }, [isOpen]);


  useEffect(() => {
    setBodyScrollLocked(isOpen);
  }, [isOpen]);

const handleButtonClick = () => {
onSubmitButtonClick();
toggleNavbar();
};

const toggleDropdown = () => {
setDropdownOpen(!isDropdownOpen);
};

useEffect(() => {
setActiveLink(pathname);
}, [pathname]);

const profileLink = isApplicationApproved ? "/expertprofile" : "/profile";
const settingsLink = isApplicationApproved ? "/expertsettings" : "/settings";

return (
    <>
      {isOpen && (
        <div className={`blurred-background${isOverlayVisible ? ' visible' : ''}`} onClick={toggleNavbar}></div>
      )}

      <div
        className={`mobile-navbar no-scroll${isOpen ? ' open' : ''}`}
        onClick={(e) => {
          // Prevent the click event from propagating
          e.stopPropagation();
        }}
        ref={navbarRef}
      >
<div className="navbar-content">
<button 
        className="btn btn-primary cartoon-shadow d-flex align-items-center cartoon-shadow " 
        id="loginButtonNavDropdown2" 
        onClick={handleButtonClick}
      >
        {isLoggedIn ? (
          <>
            {displayName ? (
              displayName.split(' ')[0]
            ) : (
              <LoadingCircleSmall />
            )}
            {isApplicationApproved && (
              <svg xmlns="http://www.w3.org/2000/svg" color='#212529' fill="#FFFFFF" viewBox="0 0 24 24" strokeWidth={1} stroke="inherit" height="25" width="25" style={{ marginLeft: '10px' }}>
                <path d="M11.7 2.805a.75.75 0 01.6 0A60.65 60.65 0 0122.83 8.72a.75.75 0 01-.231 1.337 49.949 49.949 0 00-9.902 3.912l-.003.002-.34.18a.75.75 0 01-.707 0A50.009 50.009 0 007.5 12.174v-.224c0-.131.067-.248.172-.311a54.614 54.614 0 014.653-2.52.75.75 0 00-.65-1.352 56.129 56.129 0 00-4.78 2.589 1.858 1.858 0 00-.859 1.228 49.803 49.803 0 00-4.634-1.527.75.75 0 01-.231-1.337A60.653 60.653 0 0111.7 2.805z" />
                <path d="M13.06 15.473a48.45 48.45 0 017.666-3.282c.134 1.414.22 2.843.255 4.285a.75.75 0 01-.46.71 47.878 47.878 0 00-8.105 4.342.75.75 0 01-.832 0 47.877 47.877 0 00-8.104-4.342.75.75 0 01-.461-.71c.035-1.442.121-2.87.255-4.286A48.4 48.4 0 016 13.18v1.27a1.5 1.5 0 00-.14 2.508c-.09.38-.222.753-.397 1.11.452.213.901.434 1.346.661a6.729 6.729 0 00.551-1.608 1.5 1.5 0 00.14-2.67v-.645a48.549 48.549 0 013.44 1.668 2.25 2.25 0 002.12 0z" />
                <path d="M4.462 19.462c.42-.419.753-.89 1-1.394.453.213.902.434 1.347.661a6.743 6.743 0 01-1.286 1.794.75.75 0 11-1.06-1.06z" />
              </svg>
            )}
          </>
        ) : (
          'Login'
        )}
      </button>
<InstallPromptButton showPopup={showPopup} setShowPopup={setShowPopup} />
</div>
{isLoggedIn && (
<div className="points-mobile">
<span className="points">{points} </span>
<span className="tio"> Tio-Points</span>
</div>
)}
<div className="SideBarMenu">

<div className="list-item">
<Link to="./" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/" ? ' active' : ''}`} >
Home

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
</svg>
</Link>
</div>

{isLoggedIn && (
<>
<div className="list-item">
<Link
to={profileLink}
onClick={() => {
toggleNavbar();
setActiveLink(profileLink);
}}
className={`list-item-login${activeLink === profileLink ? ' active' : ''}`}
>
{isApplicationApproved ? 'Profile' : 'Profile'}

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
</Link>
</div>

<div role="separator" className="dropdown-divider"></div>
</>
)}

<div className="list-item">
{isLoggedIn ? (
<Link to="/ExpertVerification" onClick={() => { toggleNavbar(); }} className={`position-relative list-item-login${location.pathname === "/ExpertVerification" ? ' active' : ''}`}>
Become an Expert

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>
</Link>
) : (
<Link to="/BecomeAnExpert" onClick={() => { toggleNavbar(); }} className={`position-relative list-item-login${location.pathname === "/BecomeAnExpert" ? ' active' : ''}`}>
Become an Expert

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
</svg>
</Link>
)}
</div>
{isLoggedIn && (
<div className="list-item ">
<Link to="./chat" onClick={() => { toggleNavbar(); }} className={`position-relative list-item-login${location.pathname === "/chat" ? ' active' : ''}`} >
Messages

<div className={`svg-wrapper ${hasUnreadMessages ? 'unread-svg-border' : ''}`}>
<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
</svg>
</div>
</Link>
</div>
)}
<div className="list-item">
<Link to="./chatbot" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/chatbot" ? ' active' : ''}`} >
Tio Chat

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path d="M10.5 15.5C10.5 15.87 10.4 16.2 10.22 16.5C9.88 15.91 9.24 15.5 8.5 15.5S7.12 15.91 6.78 16.5C6.61 16.2 6.5 15.87 6.5 15.5C6.5 14.4 7.4 13.5 8.5 13.5S10.5 14.4 10.5 15.5M23 15V18C23 18.55 22.55 19 22 19H21V20C21 21.11 20.11 22 19 22H5C3.9 22 3 21.11 3 20V19H2C1.45 19 1 18.55 1 18V15C1 14.45 1.45 14 2 14H3C3 10.13 6.13 7 10 7H11V5.73C10.4 5.39 10 4.74 10 4C10 2.9 10.9 2 12 2S14 2.9 14 4C14 4.74 13.6 5.39 13 5.73V7H14C17.87 7 21 10.13 21 14H22C22.55 14 23 14.45 23 15M21 16H19V14C19 11.24 16.76 9 14 9H10C7.24 9 5 11.24 5 14V16H3V17H5V20H19V17H21V16M15.5 13.5C14.4 13.5 13.5 14.4 13.5 15.5C13.5 15.87 13.61 16.2 13.78 16.5C14.12 15.91 14.76 15.5 15.5 15.5S16.88 15.91 17.22 16.5C17.4 16.2 17.5 15.87 17.5 15.5C17.5 14.4 16.61 13.5 15.5 13.5Z" />
</svg>
</Link>
</div>
<div className="list-item">
<Link to="./Blog" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/Blog" ? ' active' : ''}`} >
Blog

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ margin: '5px' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
</svg>
 



</Link>
</div>

<div className="list-item-span">
<div
className={`list-item dropdown${isDropdownOpen ? ' open' : ''}`}
onClick={toggleDropdown}
>
<div className="list-item-content">
<span className="list-item-text">Discover</span>
<div className="list-item-icon">
<span className={`dropdown-arrow ${isDropdownOpen ? 'rotate-icon' : ''}`} aria-hidden="true">
<div className="svg-inner">
<svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg">
<path d="M0.772126 1.19065L0.153407 1.80934C0.00696973 1.95578 0.00696973 2.19322 0.153407 2.33969L5.80025 8L0.153407 13.6603C0.00696973 13.8067 0.00696973 14.0442 0.153407 14.1907L0.772126 14.8094C0.918563 14.9558 1.156 14.9558 1.30247 14.8094L7.84666 8.26519C7.99309 8.11875 7.99309 7.88131 7.84666 7.73484L1.30247 1.19065C1.156 1.04419 0.918563 1.04419 0.772126 1.19065Z"></path>
</svg>
</div>
</span>
</div>
</div>
<div className={`dropdown-content${isDropdownOpen ? ' open' : ''}`}>


<div className="dropdown-item-mobile">
<Link to="/Shop" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/Shop" ? ' active' : ''}`}> Shop
</Link>
</div>
<div className="dropdown-item-mobile">
<Link to="/Pricing" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/Pricing" ? ' active' : ''}`}> Price
</Link>
</div>
<div className="dropdown-item-mobile">
<Link to="/FAQ" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/FAQ" ? ' active' : ''}`}> FAQ
</Link>
</div>
<div className="dropdown-item-mobile">
<Link to="/ExpertResources" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/ExpertResources" ? ' active' : ''}`}> Expert Resources
</Link>
</div>
</div>
</div>
</div>

<div role="separator" className="dropdown-divider"></div>

<div className="list-item">
<Link to="/OurMission" onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === "/OurMission" ? ' active' : ''}`}>
Our Mission

<svg xmlns="http://www.w3.org/2000/svg" fill="#212529" height="24" viewBox="0 -960 960 960" width="24"><path  d="M711-480Zm49 80q-17 0-28.5-11.5T720-440q0-17 11.5-28.5T760-480h77q-4-7-9-12t-12-9q-26-15-59.5-22t-76.5-7h-21q-17 0-28-11t-11-28q0-17 9.5-27.5T656-609q5-1 12-1h12q53 0 99 11t86 32q26 14 40.5 41.5T920-463v23q0 17-11.5 28.5T880-400H760Zm-80-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-760q0-17-11.5-28.5T680-800q-17 0-28.5 11.5T640-760q0 17 11.5 28.5T680-720Zm0-40ZM249-480ZM80-400q-17 0-28.5-11.5T40-440v-23q0-35 14.5-62.5T95-567q40-21 86-32t99-11h12q7 0 12 1 17 2 26.5 12.5T340-569q0 17-11 28t-28 11h-21q-43 0-76.5 7T144-501q-7 4-12 9t-9 12h77q17 0 28.5 11.5T240-440q0 17-11.5 28.5T200-400H80Zm200-240q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T320-760q0-17-11.5-28.5T280-800q-17 0-28.5 11.5T240-760q0 17 11.5 28.5T280-720Zm0-40Zm200 480q-33 0-56.5-23.5T400-360v-120q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480v120q0 33-23.5 56.5T480-280Zm0 200q-13 0-21.5-8.5T450-110v-52q-65-10-111.5-56T283-325q-2-14 7-24.5t23-10.5q11 0 19 6.5t10 17.5q11 52 49 84t89 32q51 0 89-32t49-84q2-11 10-17.5t19-6.5q14 0 23 10.5t7 24.5q-9 61-55.5 107T510-162v52q0 13-8.5 21.5T480-80Z"/></svg>

</Link>
</div>






<div role="separator" className="dropdown-divider"></div>
{isLoggedIn && (
<>

<div className="logout-container">
<div className="list-item">
<Link to={settingsLink} onClick={() => { toggleNavbar(); }} className={`list-item-login${location.pathname === settingsLink ? ' active' : ''}`}>
Settings

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" style={{ marginLeft: 'auto' }}>
<path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
<path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
</svg>
</Link>
</div>

<div className="list-item justify-content-between"
onClick={() => {
handleLogout();
toggleNavbar();
}}>

Logout

<svg xmlns="http://www.w3.org/2000/svg" color= '#212529' fill="none" viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" height="25" width="25" >
<path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
</svg>

</div>

</div>
</>

)}
</div>
</div>
</>
);
}

export default MobileNavbar;

