import React, {useState, lazy, useEffect, useLayoutEffect, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import './assets/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './components/Navbar';
import LazyFooter from './components/Footer';
import LoadingCircle from './components/LoadingCircle';
import { PopupProvider } from './boxes/PopupContext';
import Homesection from './components/Homesection';
import { auth, onAuthStateChanged, requestPermission, getFCMToken, storeFCMToken, messaging   } from './firebase'; // Import onAuthStateChanged and auth
import OfflinePage from './OfflinePage';



const LoadingIndicator = () => {
  return (
    <div style={{ minHeight: 'calc(100vh - 60px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {/* Loading indicator content, e.g., "Loading..." */}
    </div>
  );
};
const NoAcces = lazy(() => import('./NoAcces'));
const Fullpage = lazy(() => import('./Typeform/FullPage'));
const Chat = lazy(() => import('./Chat/Chat'));
const NotFound = lazy(() => import('./Notfound'));
const UserProfile = lazy(() => import('./Users/UserProfile'));
const ExpertProfile = lazy(() => import('./Users/ExpertProfile'));
const UserPage = lazy(() => import('./Users/UserPage'));
const ExpertPage = lazy(() => import('./Users/ExpertPage'));
const UserSettings = lazy(() => import('./Users/Settings'));
const ExpertSettings = lazy(() => import('./Users/ExpertSettings'));
const LazyPricingSection = lazy(() => import('./components/PricingSection'));
const BlogList = lazy(() => import('./Blogs/BlogList'));
const BlogPost = lazy(() => import('./Blogs/BlogPost'));
const ExpertResourcesList = lazy(() => import('./ExpertResources/ExpertResourcesList'));
const ExpertResourcesPost = lazy(() => import('./ExpertResources/ExpertResourcesPost'));
const LazyFAQSection = lazy(() => import('./components/FAQSection'));
const LazyOurExperts = lazy(() => import('./components/OurExperts'));
const LazyBecomeAnExpert = lazy(() => import('./components/BecomeAnExpert'));
const LazyChatInterface = lazy(() => import('./ChatBot/ChatInterface'));
const LazyAboutSection = lazy(() => import('./components/AboutUs'));
const Lazysmartanswers= lazy(() => import('./components/smartanswers'));
const LazyWhyTio= lazy(() => import('./components/WhyTio'));
const LazyWOurMission= lazy(() => import('./components/OurMission'));
const LazyWOurTeam= lazy(() => import('./components/OurTeam'));
const LazyWJoinUs= lazy(() => import('./components/JoinUs'));
const LazyWPressAndMedia= lazy(() => import('./components/PressAndMedia'));
const LazyHelpCenter= lazy(() => import('./components/HelpCenter'));
const LazyContactUs= lazy(() => import('./components/ContactUs'));
const LazyPrivacyPolicy= lazy(() => import('./components/PrivacyPolicy'));
const LazyTermsOfService= lazy(() => import('./components/TermsOfService'));
const LazyReferral= lazy(() => import('./components/Referral'));
const LazyShop= lazy(() => import('./components/Shop'));

const LoadingFallback = () => (
  <div style={{ minHeight: 'calc(100dvh - 4rem)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <LoadingCircle />
  </div>
);

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      } 
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return isAuthenticated ? children : <Navigate to="/no-access" />;
}

function ScrollToTopOnNavigation() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [pathname]);

  return null;
}

function App() {

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // User is authenticated
        console.log("User is signed in:", user);

        // Get FCM token
        let currentToken = await getFCMToken();

        if (currentToken) {
          console.log("FCM token:", currentToken);

          // Store FCM token in Firestore
          storeFCMToken(user.uid, currentToken);
        }
      } else {
        // User is signed out
        console.log("User is signed out.");
      }
    });

    return () => unsubscribe();
  }, []);
  
  if (!isOnline) {
    return <OfflinePage />;
  }

  return (
    <React.StrictMode>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <PopupProvider>
        <Navbar />
        <Routes>
          <Route path="/FAQ" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyFAQSection />
    </Suspense>
  } />
<Route path="/" element={
  <Suspense fallback={<LoadingFallback />}>
    <Home />
  </Suspense>
} />
  <Route path="/Pricing" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyPricingSection />
    </Suspense>
  } />
  <Route
          path="/ExpertResources"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <ExpertResourcesList />
            </Suspense>
          }
        />
        <Route
          path="/ExpertResources/:ExpertResourcesPostId"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <ExpertResourcesPost />
            </Suspense>
          }
        />

<Route
          path="/blog"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <BlogList />
            </Suspense>
          }
        />
        <Route
          path="/blog/:blogPostId"
          element={
            <Suspense fallback={<LoadingFallback />}>
              <BlogPost />
            </Suspense>
          }
        />

         <Route path="/about" element={
              <Suspense fallback={<LoadingFallback />}>
                <LazyAboutSection />
              </Suspense>
            } />
  <Route path="/BecomeAnExpert" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyBecomeAnExpert />
    </Suspense>
  } />
   <Route path="/chatBot" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyChatInterface />
    </Suspense>
  } />
     <Route path="/ourexperts" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyOurExperts />
    </Suspense>
  } />
     <Route path="/smart" element={
    <Suspense fallback={<LoadingFallback />}>
      <Lazysmartanswers />
    </Suspense>
  } />
   <Route path="/OurMission" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyWOurMission />
    </Suspense>
  } />
     <Route path="/OurTeam" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyWOurTeam />
    </Suspense>
  } />
     <Route path="/JoinUs" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyWJoinUs />
    </Suspense>
  } />
     <Route path="/PressAndMedia" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyWPressAndMedia />
    </Suspense>
  } />
  <Route path="/HelpCenter" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyHelpCenter />
    </Suspense>
  } />
    <Route path="/ContactUs" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyContactUs />
    </Suspense>
  } />
    <Route path="/PrivacyPolicy" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyPrivacyPolicy />
    </Suspense>
  } />
   <Route path="/TermsOfService" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyTermsOfService />
    </Suspense>
  } />
  <Route path="/Shop" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyShop/>
    </Suspense>
  } />
    <Route path="/Referral" element={
    <Suspense fallback={<LoadingFallback />}>
      <LazyReferral />
    </Suspense>
  } />


<Route path="/user/:customURL" element={
  <Suspense fallback={<LoadingFallback />}>
    <UserPage />
  </Suspense>
} />

<Route path="/expert/:customURL" element={
  <Suspense fallback={<LoadingFallback />}>
    <ExpertPage />
  </Suspense>
} />



            <Route path="/" element={<Home />} />
            <Route path="/no-access" element={<NoAcces />} />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <UserProfile />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
              <Route
              path="/expertprofile"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <ExpertProfile />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
                <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <Chat />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <UserSettings />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
             <Route
              path="/expertsettings"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <ExpertSettings />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route
              path="/ExpertVerification"
              element={
                <PrivateRoute>
                  <React.Suspense fallback={<LoadingFallback />}>
                    <Fullpage />
                  </React.Suspense>
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        <LazyFooter />
  
        <ScrollToTopOnNavigation />
        </PopupProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const LazyImageSection = lazy(() => import('./components/ImageSection'));
const LazyHooverSection = lazy(() => import('./components/HooverSection'));
const LazyCTALandingPage = lazy(() => import('./components/CTALandingPage'));
const LazyFAQ = lazy(() => import('./components/FAQ'));
const LazyArticles = lazy(() => import('./components/Articles'));
const LazyContact = lazy(() => import('./components/Contact'));
const LazyWeHelp = lazy(() => import('./components/WeHelp'));
const LazySteps = lazy(() => import('./components/Steps'));


function Home() {
  return (
    <>
     <PopupProvider>
      <Homesection />
      <LazySteps />
      <LazyWhyTio />
      <LazyImageSection />
      <LazyHooverSection />
      <LazyWeHelp />
      <LazyFAQ/>
      <LazyArticles />
      <LazyCTALandingPage />
      <LazyContact />
      </PopupProvider>
    </>
  );
}

root.render(<App />);


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
    .then(function(registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function(err) {
      console.log('Service worker registration failed, error:', err);
    });
}

if ('periodicSync' in navigator) {
  navigator.periodicSync.register('send-push-notifications', {
    startOverride: new Date().getTime() + 24 * 60 * 60 * 1000, // Start in 24 hours
    periodOverride: 24 * 60 * 60 * 1000, // Repeat every 24 hours
  })
  .then(() => {
    console.log('Periodic background sync registered for push notifications');
  })
  .catch((error) => {
    console.error('Failed to register periodic background sync:', error);
  });
} else {
  console.log('Periodic Background Sync is not supported');
}