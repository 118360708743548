import React from 'react';
import { Link } from 'react-router-dom';

const OfflinePage = () => {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
          <div className='mt-5'>
              <h1>No Internet Connection</h1>
              <p>Please check your network settings and try again.</p>
              <button onClick={() => window.location.reload()} className="btn btn-primary mb-5 cartoon-shadow">
                Retry Connection
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default OfflinePage;