import { useState, useEffect } from 'react';

const useIpad = () => {
  const [isIpad, setIsIpad] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const minWidth = 768; // Minimum iPad width
      const maxWidth = 1362; // Maximum iPad width
      setIsIpad(window.innerWidth >= minWidth && window.innerWidth <= maxWidth);
    };

    handleResize(); // Initial check

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isIpad;
};

export default useIpad;