import { useState, useEffect } from 'react';

const useBodyScrollLock = (initialValue = false) => {
  const [locked, setLocked] = useState(initialValue);

  useEffect(() => {
    const body = document.querySelector('body');
    if (locked) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'unset';
    }

    return () => {
      body.style.overflow = 'unset';
    };
  }, [locked]);

  return [locked, setLocked];
};

export default useBodyScrollLock;
