import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {  setDoc, doc, getDoc } from 'firebase/firestore';
import { auth,firestore} from '../firebase';
import { FaLinkedin,FaGlobeEurope, FaStar, FaEnvelope } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import StarRating from './StarRating';

const SearchResultCard = ({ result, otherUserUID }) => {
  console.log("SearchResultCard called with result:", result);
  const userProfileURL = `https://www.tioassist.io/expert/${result.customURL}`;
  const keywordsText = result.keywords ? result.keywords.join(', ') : '';
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);



  useEffect(() => {
    if ( otherUserUID) {
      fetchUserContacts();
    }
  }, [otherUserUID]);

  const handleChatButtonClick = () => {
    onSearchBarToggle(); 
    try {
      if (!otherUserUID) {
        throw new Error('otherUserUID is undefined or null');
      }

      navigate('/chat', {
        state: {
          otherUserUID,
        },
      });
          // Calling onSearchBarToggle after navigation
    } catch (error) {
      console.error('Error handling chat button click:', error.message);
    }
  };

  const fetchUserContacts = async () => {
    try {
      const currentUserUID = auth.currentUser.uid;
      const userProfileRef = doc(firestore, 'userProfiles', currentUserUID);
      const expertApplicationRef = doc(firestore, 'expertApplications', currentUserUID);
  
      const [userProfileDoc, expertApplicationDoc] = await Promise.all([
        getDoc(userProfileRef),
        getDoc(expertApplicationRef),
      ]);
  
      let contacts = {};
  
      if (userProfileDoc.exists()) {
        const userData = userProfileDoc.data();
        contacts = userData.contacts || {};
      }
  
      if (expertApplicationDoc.exists()) {
        const expertData = expertApplicationDoc.data();
        const expertContacts = expertData.contacts || {};
        contacts = { ...contacts, ...expertContacts };
      }
  
      // Check if the otherUserUID is in the contacts
      if (otherUserUID && contacts[otherUserUID]) {
        setIsConnected(true);
      } else {
        setIsConnected(false);
      }
    } catch (error) {
      console.error('Error fetching user contacts:', error);
    }
  };


  useEffect(() => {
    if ( otherUserUID) {
      fetchUserContacts();
    }
  }, [otherUserUID]);

  const handleConnectButtonClick = async () => {
    try {
      const currentUser = auth.currentUser;
  
      if (!currentUser || !currentUser.uid) {
        console.error('User is not authenticated or UID is not available');
        return;
      }
  
      const userProfileRef = doc(firestore, 'userProfiles', currentUser.uid);
      const expertApplicationRef = doc(firestore, 'expertApplications', currentUser.uid);
  
      const [userProfileDoc, expertApplicationDoc] = await Promise.all([
        getDoc(userProfileRef),
        getDoc(expertApplicationRef),
      ]);
  
      let contacts = {};
      let updateRef = null;
  
      if (userProfileDoc.exists()) {
        const userData = userProfileDoc.data();
        contacts = userData.contacts || {};
        updateRef = userProfileRef;
      }
  
      if (expertApplicationDoc.exists()) {
        const expertData = expertApplicationDoc.data();
        contacts = expertData.contacts || {};
        updateRef = expertApplicationRef;
      }
  
      if (updateRef) {
        await setDoc(
          updateRef,
          {
            contacts: { ...contacts, [otherUserUID]: true },
          },
          { merge: true }
        );
  
        setIsConnected(true);
      }
    } catch (error) {
      console.error('Error connecting:', error);
    }
  };
  
  

  const handleEmailClick = () => {
    if (email) {
      const mailtoLink = `mailto:${email}`;
      window.location.href = mailtoLink;
    }
  };
  const handleLinkClick = (url) => {
    if (url) {
      let formattedUrl = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        formattedUrl = 'https://' + url;
      }
      window.open(formattedUrl, '_blank');
    }
  };
  



  return (

      <div className="cardsearch">
        <div className="padding-10">



          <div className='card-container'>
            <div className='left-part'>
            <div className='links-container gap-3'>
              <div className='file-container'>
              <div className=''>
  <div  href={result.photoURL} className=' upload-wrapper remote-video-container' >
  <a href={userProfileURL}>
            <img
              src={result.photoURL}
              alt="User Profile"
              className="user-avatar medium-avatar  "
            />
              <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="black"
    className="w-6 h-6 expert-image-verified-s"
    >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
    />
  </svg>
 </a>
 <StarRating rating={result.rating} />
 </div>
        </div>
        <div className='expert-name-box '>
          <h2 className="mb-0">{result.displayName}</h2>
          <div className='d-flex'>
        </div>
        <div className='expet-box-flex gap-3'>
        <div className='d-align'>
          {result.twitterUrl && (
  <div className='expert-svg-wrapper' onClick={() => handleLinkClick(result.twitterUrl)}>
    <FaXTwitter size={30} className="text-muted " />
  </div>
)}

{result.linkedinUrl && (
  <div className='expert-svg-wrapper' onClick={() => handleLinkClick(result.linkedinUrl)}>
    <FaLinkedin size={30} className="text-muted " />
  </div>
)}

{result.websiteUrl && (
  <div className='expert-svg-wrapper' onClick={() => handleLinkClick(result.websiteUrl)}>
    <FaGlobeEurope size={30} className="text-muted " />
  </div>
)}
{result.email && (
  <div className='mail-svg-wrapper'  onClick={handleEmailClick}>
    <FaEnvelope size={20} className="icon-white"  />
  </div>
)}
    

</div>

</div>
        </div>
        
        </div>

<div className="justify-content-between-p gap-2">
<Link to="/chat" state={{ otherUserUID }}  onClick={handleChatButtonClick}>

            <button type="button" className="btn btn-primary  cartoon-shadow  rounded-pill " >
              
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="25" className="me-2 svg-inline--fa ">
                <path fillRule="evenodd" d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223zM8.25 10.875a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25zM10.875 12a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0zm4.875-1.125a1.125 1.125 0 100 2.25 1.125 1.125 0 000-2.25z" clipRule="evenodd" />
              </svg>
              <span className="me-2">Message</span> 
            </button>
        
            </Link>
            {auth.currentUser && auth.currentUser.uid !== otherUserUID && (
            <button
  type="button"
  className="btn btn-secondary cartoon-shadow-secondary btn-sm rounded-pill  "
  onClick={handleConnectButtonClick}
  disabled={isConnected}
>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="25" className="me-2 svg-inline--fa ">
    <path d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z" />
  </svg>
  <span className="me-2">{isConnected ? 'Connected' : 'Connect'}</span>
</button>
)}
</div>
<div className="justify-content-between-p">
  <div>
  <h5 className='text-muted'>Role</h5>

      <h4 >{result.occupation}</h4>
      </div>
      <div>
      <h5 className='text-muted'>Experience</h5>

      <h4 >   {result.yearsOfExperience} years</h4>
      </div>
    </div>
  </div>
  </div>
<div className='right-part'>
  <div className='gap-2 links-container'>
  <h5 className=''> Bio</h5>
  <div className="description-container-box  ">
      <div className="card-expert-body ">{result.description}</div>
    </div>
    <h5 className=''> Skills</h5>
      <div className='keywords-wrapper-m'>
        {result.expertise_areas.map((keyword, index) => (
          <div key={index} className="skill-card-m " >
            <FaStar size={20} className="me-2" />
            {keyword}
          </div>
        ))}


               
      </div>
      <div className='keywords-wrapper-m'>
        {result.keywords.map((keyword, index) => (
          <div key={index} className="keyword-card-m " >
            {keyword}
          </div>
        ))}
      </div>
</div>
          </div>
          </div>
     
     

        <div>
       
        </div>
 
</div>
      </div>
     

  );
};

export default SearchResultCard;

