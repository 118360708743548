import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import SearchResults from './SearchResults';
import Fuse from 'fuse.js';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);
  const [otherUserUIDs, setOtherUserUIDs] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    if (isInputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isInputVisible]);

  const handleSearch = async () => {
    if (searchQuery.trim() !== '') {
      const expertsCollection = collection(firestore, 'expertApplications');

      try {
        const querySnapshot = await getDocs(expertsCollection);
        const searchData = [];
        const userUIDs = [];

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const uid = doc.id;
          searchData.push({ ...data, otherUserUID: uid });
          userUIDs.push(uid);
        });

        const fuseOptions = {
          keys: ['displayName', 'occupation', 'keywords'],
          includeScore: true,
          includeMatches: true,
          threshold: 0.4,
        };

        const fuse = new Fuse(searchData, fuseOptions);

        const searchResults = fuse.search(searchQuery);
        const matchedData = searchResults.map((result) => result.item);

        setSearchResults(matchedData);
        setNoResultsMessage(matchedData.length === 0 ? 'No results found.' : '');
        setIsDropdownOpen(true);
        setOtherUserUIDs(userUIDs);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    } else {
      setSearchResults([]);
      setNoResultsMessage('');
      setIsDropdownOpen(true);
    }
  };

  const handleButtonClick = () => {
    if (!isInputVisible) {
      setIsInputVisible(true);
    } else {
      handleSearch();
    }
    setIsDropdownOpen(true);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
      setIsDropdownOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="search-bar">
      <div className={`search-container ${isInputVisible ? 'expanded' : ''}`}>
        <input
        
           type="search"
          placeholder="Search for Experts..."
          className={`form-control search-input ${isInputVisible ? 'expanded' : ''}`}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyPress}
          ref={inputRef}
          autoFocus={isInputVisible}
        />
        <button  aria-label="Search" className={`search-button ${isInputVisible ? 'expanded' : ''}`} onClick={handleButtonClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            height="30"
            width="30"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
          </svg>
        </button>
      </div>
      <SearchResults
        results={searchResults}
        isOpen={isDropdownOpen}
        noResultsMessage={noResultsMessage}
        onClose={() => setIsDropdownOpen(false)}
        onMouseLeave={handleMouseLeave}
        otherUserUIDs={otherUserUIDs}
        isFromSearchBar={true} 
      />
    </div>
  );
};

export default SearchBar;
