import React, { useState, useRef, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebase';
import SearchResultCardMobile from '../components/SearchResultCardMobile';
import { FaThumbsUp, FaTrophy, FaRocket } from 'react-icons/fa';
import Fuse from 'fuse.js';

function MobileSearch({ isSearchBarOpen, onSearchBarToggle, onClose }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [noResultsMessage, setNoResultsMessage] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const inputRef = useRef(null);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [otherUserUID, setOtherUserUID] = useState([]);

    // New state variables
    const [locationQuery, setLocationQuery] = useState('');
    const [skillsQuery, setSkillsQuery] = useState('');
    const [selectedCommunicationMethod, setSelectedCommunicationMethod] = useState('all');
    const [sortOption, setSortOption] = useState('relevance');
    const [jobSuccess, setJobSuccess] = useState('');

    const toggleAdvancedSearch = () => {
        setShowAdvancedSearch(!showAdvancedSearch);
    };

    const handleSearch = async () => {
        if (searchQuery.trim() !== '') {
            const expertsCollection = collection(firestore, 'expertApplications');

            try {
                const querySnapshot = await getDocs(expertsCollection);
                const searchData = [];
                const userUIDs = [];

                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    const uid = doc.id;
                    searchData.push({ ...data, otherUserUID: uid });
                    userUIDs.push(uid);
                });

                const fuseOptions = {
                    keys: ['displayName', 'occupation', 'keywords', 'expertise_areas'],
                    includeScore: true,
                    includeMatches: true,
                    threshold: 0.4,
                };

                const fuse = new Fuse(searchData, fuseOptions);

                const searchResults = fuse.search(searchQuery);
                const matchedData = searchResults.map((result) => result.item);

                // Filter and sort matchedData based on locationQuery, skillsQuery, selectedCommunicationMethod, sortOption, and jobSuccess

                setResults(matchedData);
                setNoResultsMessage(matchedData.length === 0 ? 'No results found.' : '');
                setIsDropdownOpen(true);
                setOtherUserUID(userUIDs);
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        } else {
            setResults([]);
            setNoResultsMessage('');
            setIsDropdownOpen(true);
        }
    };

    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && (searchQuery.trim() !== '' || skillsQuery.trim() !== '')) {
    
            handleSearch();
        }
    };

    const handleSearchIconClick = () => {
        console.log('Search icon clicked');
        handleSearch();
    };

    useEffect(() => {
        if (isSearchBarOpen) {
            inputRef.current.focus();
        }
    }, [isSearchBarOpen]);

    const handleResultClick = () => {
      onClose(); // This will close the search bar
      setIsDropdownOpen(false);
      setSearchQuery('');
      setResults([]);
    };
    return (

    <div className={`search-navbar${isSearchBarOpen ? ' open' : ''}`}>
      <div className="mobile-search-bar">
        <div className="input-container">
          <div className="search-icon" onClick={onSearchBarToggle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              color="#ccc"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              height="30"
              width="30"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
            </svg>
          </div>
          <input
          type="search"
          className="mobile-search-input"
          placeholder="Search for Experts"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyPress}
          ref={inputRef}
        />
    <div className="search-icon" onClick={handleSearchIconClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              color="#ccc"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              height="30"
              width="30"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </div>
        </div>
        <div className="col-md-3">
        <div className="list-item-content mb-1" onClick={toggleAdvancedSearch}>
        <span className="list-item-text">Search Options</span>
        <div className="list-item-icon">
          <span className={`dropdown-arrow ${showAdvancedSearch ? 'rotate-icon' : ''}`} aria-hidden="true">
            <svg width="8" height="16" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.772126 1.19065L0.153407 1.80934C0.00696973 1.95578 0.00696973 2.19322 0.153407 2.33969L5.80025 8L0.153407 13.6603C0.00696973 13.8067 0.00696973 14.0442 0.153407 14.1907L0.772126 14.8094C0.918563 14.9558 1.156 14.9558 1.30247 14.8094L7.84666 8.26519C7.99309 8.11875 7.99309 7.88131 7.84666 7.73484L1.30247 1.19065C1.156 1.04419 0.918563 1.04419 0.772126 1.19065Z"></path>
            </svg>
          </span>
  </div>
</div>

      <div className={`advanced-searchmobile${showAdvancedSearch ? ' open' : ''}`}>
              <div className="form-group">
  <label className="jobSuccessSelect mb-1">Job Success:</label>

  <ul className="listcard ps-2">
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="anyJobSuccess" name="jobSuccess"  />
    <label className="form-check-label label-with-svg" htmlFor="anyJobSuccess">
      <FaThumbsUp color="#9926f0" size={25} />
      <span>Most Helpful</span>
    </label>
  </li>
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="jobSuccess80" name="jobSuccess"  />
    <label className="form-check-label label-with-svg" htmlFor="jobSuccess80">
      <FaTrophy color="#9926f0" size={25} />
      <span>Rising Tio</span>
    </label>
  </li>
  <li className="form-check">
    <input type="radio" className="form-check-input form-correct" id="jobSuccess90" name="jobSuccess" checked={jobSuccess === 'fastestResponse'} onChange={() => setJobSuccess('fastestResponse')} />
    <label className="form-check-label label-with-svg" htmlFor="jobSuccess90">
      <FaRocket color="#9926f0" size={25} />
      <span>Fastest Response</span>
    </label>
  </li>
</ul>
</div>


<div className="form-group mb-1">
              <label htmlFor="sortSelect">Sort By:</label>
              <select
                className="form-select mb-2"
                id="sortSelect"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="relevance">Relevance</option>
                <option value="rating">Rating</option>
                <option value="experience">Experience</option>
                <option value="fastestResponse">Fastest Response</option>
              </select>
            </div>

  <div className="form-group mb-1">
              <label htmlFor="communicationMethodSelect">Preferred Communication Method:</label>
              <select
                className="form-select mb-2"
                id="communicationMethodSelect"
                value={selectedCommunicationMethod}
                onChange={(e) => setSelectedCommunicationMethod(e.target.value)}
              >
                <option value="chat">Chat</option>
                <option value="video">Video Call</option>
                <option value="email">Email</option>
                <option value="all">All</option>
              </select>
            </div>



            <div className="form-group mb-2">
              <label htmlFor="locationInput">Location:</label>
              <input
                type="text"
                className="form-control"
                id="locationInput"
                placeholder="Enter location"
                value={locationQuery}
                onChange={(e) => setLocationQuery(e.target.value)}
              />
            </div>


<div className="form-group">
  <label htmlFor="talentTypeSelect" className="mb-1">Select an option:</label>
  <select className="form-select mb-2" id="talentTypeSelect">
    <option value="">Experts & Business</option>
    <option value="expert">Expert</option>
    <option value="business">Business</option>
  </select>
</div>

<div className="form-group">
              <label htmlFor="skillsInput">Skills:</label>
              <input
                type="text"
                className="form-control"
                id="skillsInput"
                placeholder="Enter skills tags"
                value={skillsQuery}
                onChange={(e) => setSkillsQuery(e.target.value)}
              />
            </div>

<div className="row">
<div className="row justify-content-space-between ">
                <button className="listbutton signup-button" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>

      </div>
    </div>
    <div className="mobile-search-results">
        {noResultsMessage && <p>{noResultsMessage}</p>}
        {results.map((result, index) => (
          <SearchResultCardMobile 
            key={result.id || index} 
            otherUserUID={result.otherUserUID} 
            result={result} 
            onSearchBarToggle={onSearchBarToggle}
            onResultClick={handleResultClick}
          />
        ))}
      </div>
    </div>
    </div>
  );
}

export default MobileSearch;

